/*******************************************************************************
* REFERENCE
*******************************************************************************/

.partners-block-wrapper {
    .parner-hover   {
        p {
            display: none;
        }
    }
}

.reference-section  {
    .headline-block {
        h4 {
            display: none;
        }
    }
}

.reference-logo{
    border-bottom: 2px solid #f2f2f2;
    padding: 102px 0 101px;

    img {
        max-height: 135px;
        max-width: 400px;
    }
}
.reference-section{
    padding: 0 0 162px;
    .headline-block {
        padding: 50px 0;
        h1{
            margin: 20px 0 33px;
        }
        h4{
            margin: 0 0 5px;
        }
    }
}
.styled-listing{
    ul,li{
        list-style: none;
        margin: 0;
    }
    ul {
        padding-left: 29px;
    }
    li{
        margin: 0 0 26px;
        padding-left: 35px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 10px;
            height: 10px;
            background-color: $secondary-color;
        }
    }
    h3{
        color: $brand-color;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.04px;
        sup{
            font-size: 45%;
            top: -8px;
        }
    }
    p{
        color: $light-grey;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.03px;
    }
}
@include breakpoint-max(1199){
    .styled-listing ul {
        padding-left: 0;
    }
}
@include breakpoint-min-max(992,1199){
    .reference-section{
        padding: 0 0 70px;
        .headline-block {
            p{
                line-height: 40px;
                font-size: 25px;
                line-height: 40px;
            }
        }
    }
}
@include breakpoint-min-max(768,991){
    .reference-logo {
        padding: 82px 0;
        img {
            max-width: 250px;
        }
    }
    .quote-leftside-img {
        max-width: 600px;
    }
    .reference-section {
        padding: 0 0 40px;
        .headline-block {
            padding: 78px 0 20px;
        }
    }
    .styled-listing {
        margin: 0 0 70px;
        li {
            margin: 0 0 30px;
            padding-left: 25px;
            :before {
                top: 7px;
            }
        }       
    }
}
@include breakpoint-max(767){
    .reference-section{
        padding: 0 0 10px;
        .headline-block {
            padding: 46px 0 20px;
            h1 {
                margin: 15px 0;
            }
        }
    }
    .reference-logo {
        padding: 50px 0;
        img {
            max-width: 190px;
        }
    }
    .styled-listing {
        margin: 0 0 40px;
        h3 {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            color: $brand-color;
        }
        p{
            font-size: 14px;
            letter-spacing: 0;
            line-height: 23px;
        }
        li {
            margin: 0 0 25px;
            padding-left: 20px;
            &:before {
                height: 6px;
                top: 7px;
                width: 6px;
            }
        }
    }
}

//*******************************************************************************
// REFERENCE SLIDER HOMEPAGE
//*******************************************************************************
.references-homepage {
    position: relative;
    padding-bottom: 0 !important;

    @include breakpoint-max(991) {  min-height: 0; }
}

.references-homepage-slider {

    .owl-prev,
    .owl-next {
        @include vertical-align(absolute);

        span { display: none; }

        &:hover,
        &:active {
            background-color: transparent !important; 
        }

        @include breakpoint-max(991) {
            display: block !important;
            top: initial !important;
            bottom: -10px;
        }

        @include breakpoint-max(500) {
            bottom: 0;
            height: 21px !important;
            background-size: 100% 100% !important;
        }
    }

    .owl-prev {
        left: 30px;
        right: initial !important;
        background-image: url(../img/owl-prev.svg) !important;
    }

    .owl-next {
        right: 30px;
        left: initial !important;
        background-image: url(../img/owl-next.svg) !important;
    }
    
    .owl-nav {
        margin-top: 0;
    }

    .container {
        position: absolute;
        top: 0;
        @include horizontal-align();

        @include breakpoint-max(768) { width: 100%; }
    }

    .headline-block h2 {
        color: white;
        font-weight: 400;

        @include breakpoint-max(500) { font-size: 15px; }
    }

    .more-link {
        position: absolute;
        bottom: 100px;
        @include horizontal-align();

        @include breakpoint-min-max(992, 1199) { bottom: 50px; }
        @include breakpoint-max(991) { bottom: 20px; }

        &:hover {
            color: white;
        }
    }

    .owl-stage {
        @include breakpoint-max(768) { height: 50vh; }
    }

    .owl-item {
        @include breakpoint-max(768) { height: 100%; }
    }

    .owl-item .owl-item-image {
        @include breakpoint-max(768) { display: none !important; }
    }

    .owl-item-backgroundimage {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}