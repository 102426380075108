/*******************************************************************************
* X.X - Cookies
*******************************************************************************/
#div-cookie {
	@include border-radius(5px);
	background-color: $brand-color;
	position: fixed;
	left: 10px;
	bottom: 10px;
	width: 260px;
	padding: 20px;
	z-index: 9999;
	border: 1px solid #ffffff;

	&.popup-off {display: none;}

	.content {
		//padding: 24px 200px 32px 0;
		position: relative;
		margin-bottom: 0;
		//@media screen and (max-width: $bkp_lg) {padding-right: 0;}
	}
	p, .buttons {
		display: inline-block;
		vertical-align: middle;
	}
	p {
		color: white;
		font-size: 11px;
		font-weight: 400;
		line-height: 1.4em;
		//@media screen and (max-width: $bkp_lg) {
		//	font-size: 14px;
		//	margin-bottom: 16px;
		//}
		a {
			color: $secondary-color;
			display: block;

			&:hover {color: $secondary-color; text-decoration: underline;}

		}
	}
	.buttons {
		float: left;
		position: relative;
		right: 0;
		top: 0;
		height: 100%;
		//@media screen and (max-width: $bkp_lg) {
		//	float: none;
		//	position: relative;
		//	top: auto;
		//	right: auto;
		//}
		a {
			position: relative;
			top: 0;

			background-color: $secondary-color;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.25em;
			text-transform: uppercase;
			padding: 10px 20px;
			text-align: center;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			-ms-border-radius: 3px;
			-o-border-radius: 3px;
			border-radius: 3px;
			
			
			//@media screen and (max-width: $bkp_lg) {
			//	top: auto;
			//	transform: none;
			//	-ms-transform: none; /* IE 9 */
			//	-webkit-transform: none; /* Chrome, Safari, Opera */
			//}
		}
	}
}