/*******************************************************************************
* 0.1 GENERAL FOR ALL PAGE
*******************************************************************************/
.parallax-img {
  @include bg-img;
  @include absoluted-fullwidth;
  pointer-events: none;
}

.parallax-parent {
  position: relative;
  min-height: 400px;
}

.grey-section {
  padding: 0 0 57px;
  background-color: #f3f1f1;
  color: $black;
  .headline-block {
    padding: 60px 0;
    h2 {
      margin: 0;
    }
    h4 {
      margin: 1px 0 33px;
    }
    p {
      margin: 3px 0 27px;
      letter-spacing: -0.05px;
      font-weight: $light;
    }
  }
  @include breakpoint-min-max(768, 991) {
    .headline-block {
      p {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
  @include breakpoint-max(767) {
    padding: 0 0 37px;
    .headline-block {
      padding: 23px 0;
      h2 {
        font-size: 26px;
        line-height: 26px;
        margin: 0 0 -2px;
      }
      h4 {
        font-size: 14px;
        letter-spacing: -0.03px;
        margin: 1px 0 5px;
      }
      p {
        font-size: 12px;
        letter-spacing: -0.02px;
        margin: 3px 0 4px;
      }
    }
  }
}

.saperator {
  width: 259px;
  height: 10px;
  margin: 20px 0;
  background-image: linear-gradient(to right, #faaa38 0%, #4b29e9 52%, #0d0240 100%);
  @include transition(all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1))
  @include clearfix;
  @include breakpoint-max(767) {
    height: 6px;
    margin: 8px 0 9px;
    width: 101px;
  }
}

blockquote {
  color: $brand-color;
}

.overlay {
  position: relative;
  &:before {
    @include absoluted-fullwidth;
    content: '';
    pointer-events: none;
    background-color: rgba($brand-color, 0.5);
  }
}

.parallax-parent {
  .more-link {
    color: white;
  }
}

.more-link {
  color: $brand-color;
  font-size: 30px;
  font-weight: $light;
  letter-spacing: -0.05px;
  position: relative;
  z-index: 2;

  &.black-underline {
    &:after {
      background-color: black;
      bottom: 0px;
    }

    &:active, &:focus {
      color: inherit;
    }
  }
  &:after {
    background-color: #fff;
    bottom: 6px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    @include transition(all 300ms cubic-bezier(0.645, 0.045, 0.355, 1))
  }
  &:hover {
    color: inherit;
    &:after {
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
  }
  @include breakpoint-min-max(992, 1199) {
    .more-link {
      font-size: 28px;
    }
  }
  @include breakpoint-min-max(768, 991) {
    font-size: 25px;
  }
  @include breakpoint-max(767) {
    font-size: 14px;
    letter-spacing: -0.03px;
    &:after {
      bottom: 2px;
      height: 1px;
    }
  }
}

.owl-carousel.owl-loaded .owl-nav {
  button {
    height: 41px;
    width: 26px;
    position: absolute;
    top: 50%;
    margin-top: -13.5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .owl-prev {
    right: -40px;
    background-image: url(../img/owl-next.svg);
    @include transition(all 0.2s ease);

    &.disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
  .owl-next {
    left: -40px;
    background-image: url(../img/owl-prev.svg);
    @include transition(all 0.2s ease);

    &.disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
  @include breakpoint-min-max(992, 1400) {
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
    }
  }
  @include breakpoint-max(991) {
    .owl-prev, .owl-next {
      display: none;
    }
  }
}

.brand-fitness-block {
  background-color: $secondary-color;
  bottom: 47px;
  max-width: 300px;
  padding: 20px 14px 18px 85px;
  position: fixed;
  right: 40px;
  text-align: center;
  width: 100%;
  z-index: 99;
  opacity: 0;
  @include transition(all 0.6s ease);
  color: $white;
  @include border-radius(5px);

  @include breakpoint-max(768) {
    padding: 20px 14px 18px 14px;
    text-align: center;
  }

  &:before {
    width: 59px;
    height: 43px;
    content: "";
    position: absolute;
    left: 11px;
    top: 50%;
    @include translate(0, -50%);
    background: url(../img/arrow_big_right.svg) no-repeat center center;

    @include breakpoint-max(768) {
      content: none;
    }
  }

  p {
    font-weight: $medium;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.04px;
    margin: 0 0 10px;
    text-align: left;
  }
  &:focus,
  &:hover {
    color: $white;
  }
  &.visible {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  @include breakpoint-max(1199) {
    .brand-fitness-block {
      bottom: 17px;
      right: 20px;
    }
  }
  @include breakpoint-max(786) {
    //display: none;
    bottom: 15px;
    max-width: 200px;
    padding: 8px 14px 0 14px;
    right: 15px;
    opacity: 0 !important;

    &.show.visible {
      opacity: 1 !important;
    }


    p {
         font-size:14px;
    }
  }

  @include breakpoint-max(575) {
    max-width: 200px;
    padding: 8px 14px 0 14px;
  }
}

main {
  margin-top: 68px;
  @include breakpoint-max(767) {
    margin-top: 49px;
  }
}

.front-page {
  main {
    margin: 0;
  }
}

.read-more {
  color: #63666a;
  letter-spacing: -0.03px;
  &:before {
    content: "";
    display: inline-block;
    height: 10px;
    left: -1px;
    margin-right: 14px;
    position: relative;
    width: 40px;
    background-image: linear-gradient(to right, #faaa38 0%, #4b29e9 52%, #0d0240 100%);
    @include transition(all 0.3s ease);
  }
  &:hover {
    &:before {
      width: 70px;
    }
  }
  @include breakpoint-max(767) {
    &:before {
      height: 6px;
      left: 0;
      margin-right: 10px;
      top: -2px;
      width: 23px;
    }
    &:hover {
      &:before {
        width: 40px;
      }
    }
  }
}

.grey-bg {
  background-color: #f3f1f1 !important;
}

.icon {
  display: block;
  width: 29px;
  height: 32px;
  background-position: center center;
  background-repeat: no-repeat;

  &.icon-document {
    background-image: url(../img/doc-icon.svg);
  }

  &.icon-download {
    background-image: url(../img/download-icon.svg);
  }

  &.icon-mappin {
    height: 26px;
    width: 16px;
    background-image: url(../img/map-pin.svg);
  }

  // Mobile
  @include breakpoint-max(767) {
    background-size: contain;
    height: 13px;
    width: 19px;
    &.icon-mappin {
      height: 18px;
      width: 14px;
    }
  }

}