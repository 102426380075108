/*******************************************************************************
* CONTENT SECTION
*******************************************************************************/

h1 {
    color: $brand-color;
}

body {
    color: $brand-color;
}

.blank-page {
    h1{
        text-align: center;
        padding-top: 20px;
    }
}

.content-section{
    padding: 103px 0;
    color: $brand-color;
    p{
        font-size: 30px;
        line-height: 45px;
        margin: 0 0 45px;
    }
    h2{
        color: inherit;
        line-height: 50px;
        font-size: 50px;
        font-weight: $medium;
        letter-spacing: -0.09px;
        margin: 100px 0 56px;
    }
}
.life-cycle-img {
    margin: 106px 0 26px;

    img {
        width: 1100px;
        height: 100%;
        max-width: 100%;
    }
}

.why-block {
    img {
        width: 945px;
        height: 100%;
        max-width: 100%;
    }
}

@include breakpoint-min-max(768,991){
    .content-section{
        padding: 119px 0 50px;
        p {
            font-size: 22px;
            line-height: 36px;
            margin: 0 0 35px;
        }
        h2 {
            font-size: 40px;
            line-height: 50px;
            margin: 30px 0 36px;
        }
    }
    .life-cycle-img {
        margin: 60px 0 26px;
    }
}
@include breakpoint-max(767){
    .content-section{
        padding: 45px 0 6px;
        p{
            font-size: 14px;
            letter-spacing: -0.03px;
            line-height: 20px;
            margin: 0 0 20px;
        }
        h2{
            font-size: 24px;
            letter-spacing: -0.04px;
            line-height: 25px;
            margin: 19px 0 20px;
        }
    }
    .life-cycle-img {
        margin: 30px 0 40px;
    }
}

.right-form-cont {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
}
