// Media Query Breakpoints
@mixin breakpoint-max($max) {
    @media (max-width: $max+'px') { @content; }    
}
@mixin breakpoint-min($min) {
    @media (min-width: $min+'px') { @content; }
}
@mixin breakpoint-min-max($min,$max) {
    @media (min-width: $min +'px') and (max-width: $max+'px') { @content; }    
}

// General
@mixin absoluted-fullwidth{
    height: 100%;
    width: 100%;
    position: absolute;
    left:0;
    top:0;
}
@mixin absoluted-center-center{
    position: absolute;
    left:50%;
    top:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
@mixin bg-img{
    background-size: cover;
    background-position: center center;
}
@mixin sqarebox($val){
    height: $val + px;
    width: $val + px;
}
@mixin ractanglebox($width,$height){
    height: $height + px;
    width: $width + px;
}
@mixin default_transition{
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
// ----------------------------------------------------------------------
// Hover and Focus
// ----------------------------------------------------------------------
// example @include hoverFocus('text-decoration', 'none');
@mixin hoverFocus($property, $value) {
    &:hover, &:focus {
        #{$property}: $value;
    }
}

// ----------------------------------------------------------------------
// Display inline block cross browser
// ----------------------------------------------------------------------
// example: @include inline-block();
@mixin inline-block() {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
}

// ----------------------------------------------------------------------
// Clearfix after element
// ----------------------------------------------------------------------
// example: @include clearfix();
@mixin clearfix() {
    & {
        *zoom: 1;
    }
    &:before,
        &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// ----------------------------------------------------------------------
// Retina Images
// ----------------------------------------------------------------------
// example: @include retina("logo2x.png", 100, 50);
@mixin retina($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
        background-image: url("#{$image}");
        background-size: $width + px $height + px;
        //background-size: $width / 10 + rem $height / 10 + rem; // Use this if you've set HTML font size value to 62.5%
        background-size: $width / 16 + rem $height / 16 + rem;
    }
}

@mixin double-transition($property1, $duration1, $timing-function1, $delay1, $property2, $duration2, $timing-function2, $delay2) {
    -webkit-transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
    -moz-transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
    -o-transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
    transition: $property1 $duration1 $timing-function1 $delay1, $property2 $duration2 $timing-function2 $delay2;
}

// Placeholder mixin
@mixin placeholder($color) {
    ::-webkit-input-placeholder {color: $color}
    :-moz-placeholder {color: $color}
    ::-moz-placeholder {color: $cgp_black}
    :-ms-input-placeholder {color: $color}
    ::-ms-input-placeholder {color: $color}
    ::placeholder {color: $color}
}

// Aspect ratio mixin
@mixin aspect-ratio($width, $height) {
    position: relative;
    
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
}

@mixin user-select() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// generic transform
@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

// generic transform
@mixin transform($transforms...) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
    @include transform(rotate($deg));
}
 
// scale
@mixin scale($scale) {
    @include transform(scale($scale));
} 

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// translate
@mixin translate3d ($x, $y, $z) {
    @include transform(translate3d($x, $y, $z));
}

// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

//Border radius
@mixin border-radius ($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow ($input) {
    -webkit-box-shadow: $input;
    -moz-box-shadow: $input;
    -ms-box-shadow: $input;
    -o-box-shadow: $input;
    box-shadow: $input;
}

@mixin columns ($num, $gap) {
    -moz-column-count: $num;
    -webkit-column-count: $num;
    column-count: $num;
    -moz-column-gap: $gap;
    -webkit-column-gap: $gap;
    column-gap: $gap;
}

// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin inline-flexbox() {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
    -webkit-box-flex: $values;
         -moz-box-flex: $values;
            -webkit-flex: $values;
            -ms-flex: $values;
                    flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
         -moz-flex-direction: $direction;
            -ms-flex-direction: $direction;
                    flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
         -moz-flex-wrap: $wrap;
            -ms-flex-wrap: $wrap;
                    flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
         -moz-flex-flow: $flow;
            -ms-flex-flow: $flow;
                    flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
    -webkit-box-ordinal-group: $val;  
         -moz-box-ordinal-group: $val;     
                 -ms-flex-order: $val;     
                    -webkit-order: $val;  
                        order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
         -moz-flex-grow: $grow;
            -ms-flex-grow: $grow;
                    flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
         -moz-flex-shrink: $shrink;
            -ms-flex-shrink: $shrink;
                    flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
         -moz-flex-basis: $width;
            -ms-flex-basis: $width;
                    flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
         -moz-justify-content: $justify;
            -ms-justify-content: $justify;
                    justify-content: $justify;
                        -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($align) {
    -webkit-align-content: $align;
         -moz-align-content: $align;
            -ms-align-content: $align;
                    align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
    -webkit-align-items: $align;
         -moz-align-items: $align;
            -ms-align-items: $align;
                    align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($align) {
    -webkit-align-self: $align;
         -moz-align-self: $align;
            -ms-align-self: $align;
                    align-self: $align;
}

// Gradient
@mixin gradient-horizontal-three($start-color, $middle-color, $end-color, $start-percent, $middle-percent, $end-percent) {
    background-image: -webkit-linear-gradient(left, $start-color $start-percent, $middle-color $middle-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(left, $start-color $start-percent, $middle-color $middle-percent, $end-color $end-percent); // Opera 12
    background-image: linear-gradient(to right, $start-color $start-percent, $middle-color $middle-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

@mixin gradient-directional-custom($variable) {
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient($variable); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient($variable); // Opera 12
    background-image: linear-gradient($variable); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

// Alignments
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

@mixin horizontal-align() {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

@mixin horizontal-vertical-align($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

// Filters
@mixin filter-default($value) {
    -webkit-filter: $value; /* Safari 6.0 - 9.0 */
    filter: $value;
}

@mixin filter-grayscale($percent: 100%) {
    -webkit-filter: grayscale($percent); /* Safari 6.0 - 9.0 */
    filter: grayscale($percent);
}