/*******************************************************************************
* BLOG LIST SECTION
*******************************************************************************/

.info-cont {
    margin: 0 0 26px;
}

.share-list {
    padding: 0;
    margin: 0;


    li {
        display: inline-block;
        padding: 0 5px;

        a {
            display: block;
            height: 18px;
        }

        .share-mail {
            background: url(../img/social_icons/mail_icon.svg) no-repeat center center;
            width: 23px;
        }

        .share-fb {
            background: url(../img/social_icons/facebook_icon.svg) no-repeat center center;
            width: 18px;
        }

        .share-lin {
            background: url(../img/social_icons/linkedin_icon.svg) no-repeat center center;
            width: 18px;
        }

        .share-tw {
            background: url(../img/social_icons/twitter_icon.svg) no-repeat center center;
            width: 18px;
        }
    }
}

.newsletter-subsciption {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);

    @include breakpoint-max(768) {
        display: block;
    }

    span {
        color: $brand-color;
        font-size: 20px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.04px;
        padding-right: 32px;
        display: block;

        @include breakpoint-max(768) {
            margin: 16px 0;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .btn {
        min-width: 185px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        margin: 0 !important;

        background-color: $secondary-color;
        border: 1px solid $secondary-color;
        color: $white;
        &:hover,&:focus,&:active{
            color: $secondary-color;
            background-color: transparent;
        }

    }
}

.blog-list-section{
    padding: 0 0 150px;
    .headline-block {
        padding: 82px 0 97px;
        .saperator{
            margin: 20px 0 25px;
        }
        h4{
            margin: 0 0 5px;
        }
    }
    .btn {
        margin: 50px 0 0;
    }
}
.blog-block{
    margin: 0 0 73px;
    &.full-width{
        margin: 0 0 73px;
        figure{
            margin: 0;
        }
        h2{
            color: $brand-color;
            font-weight: $medium;
            font-size: 50px;
            letter-spacing: -0.09px;
            line-height: 50px;
            margin: 29px 0 16px;
        }

        .info-cont {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            margin: 0 0 32px;
        }

        .blog-date{
            margin: 0;
        }
        p{
            margin: 0 0 40px;
        }
    }
    figure{
        position: relative;
        &:before{
            content: '';
            z-index: 1;
            background-color: rgba(0,0,0,0.0);
            @include absoluted-fullwidth;
            @include transition(all,0.4s,ease);
        }
    }
    figure {
        margin: 0 0 42px;
    }
    h2{
        color: $defalut-color;
        margin: 20px 0 6px;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.04px;
    }
    p{
        color: #63666a;
        margin: 0 0 29px;
    }
    &.blog-block--hovered{
        .read-more{
            &:before {
                width: 70px;
            }
        }
        figure{
            &:before{
                background-color: rgba($brand-color,0.5);
            }
        }
    }
}
.blog-date{
    color: $brand-color;
    font-size: 20px;
    font-weight: $medium;
    line-height: 25px;
    letter-spacing: -0.04px;
    margin: 0 0 20px;
}
@include breakpoint-min-max(992,1199){
    .blog-block{
        margin: 0 0 55px;
        &.full-width{
            margin: 0 0 55px;
            figure{
                margin: 0;
            }
            h2{
                font-size: 40px;
                line-height: 42px;
            }
        }
        figure {
            margin: 0 0 30px;
        }
    }
}
@include breakpoint-min-max(768,991){
    .blog-list-section{
        padding: 0 0 120px;
        .headline-block{
            padding: 90px 0 50px;
        }
    }
    .blog-block{
        &.full-width{
            h2{
                font-size: 38px;
                line-height: 44px;
            }
        }
    }
}
@include breakpoint-max(767){
    .blog-list-section{
        padding: 0 0 60px;
        .headline-block {
            padding: 23px 0 20px 0;
            .saperator {
                margin: 8px 0 18px;
            }
            h1{
                margin: 15px 0 25px;
            }
            p{
                margin: 0;
            }
        }
        .btn {
            margin: 41px 0 0;
        }
    }
    .blog-block{
        margin: 0 0 37px;
        &.full-width{
            margin: 0 0 39px;
            h2{
                margin: 22px 0 9px;
                font-size: 24px;
                line-height: 25px;
                letter-spacing: -0.04px;
            }
            p{
                display: block; 
                color: $light-grey;
                font-size: 14px;
                line-height: 26px;
                letter-spacing: -0.03px;
                margin: 0 0 20px;
            }

            .info-cont {
                display: block;
            }

            .blog-date{
                margin: 0 0 22px;
            }
        }
        figure{
            margin: 0 0 10px;
        }
        h2{
            color: #949494;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03px;
            margin: 12px 0 10px;
        }
        p{
            display: none;
        }
        .read-more{
            display: none;
        }
    }
    .blog-list-wrapper{
        .row{
            margin: 0 -7.5px;
        }
        [class*="col-"]{
            padding: 0 7.5px;
        }
    }

    .blog-date{
        color: #d0d0d0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03px;
    }
}