/*******************************************************************************
* TESTIMONIALS
*******************************************************************************/
.testimonials-section {
    padding: 95px 0 0;
}


.testimonials-slider-init{

    .quote-block{
        width: 100%;
        float: left;
        padding: 0 15px;
    }

}


@include breakpoint-max(991){

    .testimonials-slider-init.owl-carousel {

        .owl-dots {
            padding: 30px 0 0;
            text-align: center;
            width: 100%;
            @include inline-block;
        }

        .owl-dot{
            width: 13px;
            height: 13px;
            margin: 0 5px;
            border-radius: 50%;
            border: 2px solid $brand-color;
            //background-color: rgba($brand-color, 0.5);

            @include inline-block;
            @include default_transition;
            &.active,&:hover{
                background-color: $brand-color;
            }
        }

    }

}


@include breakpoint-min-max(768,991){

    .testimonials-section {
        padding: 95px 0 0;
    }

}


@include breakpoint-max(767){

    .testimonials-section {
        padding: 55px 0 0;
    }

}