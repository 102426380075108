/*******************************************************************************
* 0.3 GENERAL HEADER
*******************************************************************************/

.header-main {
	background-color: $brand-color;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	height: 70px;
	padding: 0 30px;
	//padding: 23px 30px 9px;

	@include default_transition;

}

.front-page {

	//.logo {
	//	opacity: 0;
	//	@include transition(opacity 500ms ease-in);
    //
	//	img {display: none !important;}
	//}

	.header-main {
		//background-color: transparent;
		background-color: $brand-color;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

		&.fixed {
			background-color: $brand-color;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

			.logo {opacity: 1;}
		}

	}

}

.logo {
	left: 50%;
	position: absolute;
	top: 17px;
	z-index: 9;
	width: 180px;
	height: 86px;

	@include transform(translate(-50%,0));
	//@include default_transition;

	.navigation--open & {opacity: 1;}

	&:before {
		background: url(data:image/svg+xml;base64,#{$logo_normal}) no-repeat scroll center top / 100% auto;
		display: block;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 36px;

		//@include transition(background, 500ms, ease-in, 0);

		.navigation--open & {
			background: url(data:image/svg+xml;base64,#{$logo_white}) no-repeat scroll center top / 100% auto;
			//@include transition(background, 500ms, ease-in, 0);
		}

	}

}


.lang-dropdown{
	cursor: pointer;
	float: right;
	position: relative;
	top: 0;
	z-index: 5;
	top: 50%;

	@include translate(0,-50%);

	select{
		height: 25px;
		background-color: transparent;
		box-shadow: none;
		outline: none;
		text-transform: uppercase;
		border: none;
		line-height: 16px;
		width: 66px;
		letter-spacing: -0.04px;
		padding: 0 20px 0 10px;
	}

	option{
		padding: 6px 5px;
		color: $defalut-color;
	}

	// Faker
	.faker {
		width: 60px;
		height: 60px;
		float: right;

		& .drop-value, & .drop-handle {height: 60px;}

		.drop-handle {
			width: 15px;
			background-color: transparent;
			height: 100%;
			right: 0;
			top: 0;
			border: none;

			&:after {
				//background: url(../img/select-down-arrow-white.png) no-repeat scroll right center;
				background: url(data:image/svg+xml;base64,#{$down_arrow_white}) no-repeat scroll right center;
				content: "";
				top: 50%;
				left: 50%;
				height: 100%;
				width: 100%;
			}

		}

		.drop-value {
			border: none;
			font-size: 20px;
			font-weight: $medium;
			color: #fff;
			padding-left: 0;
			padding-right: 0;
			line-height: 60px;
			background-color: transparent;
		}

		.drop-selection {
			top: 60px;

			div {
				padding-left: 0;
				text-align: center;
				background-color: white;
				border-left: 0;
				border-right: 0;

				&:hover {
					background-color: #959698;
				}

				&.active {
					background-color: #959698;
				}
			}

		}

	}

}

// Mobile
@include breakpoint-max(767){
	
	.header-main {
		height: 52px;
		padding: 0 15px;
	}

	.logo {
		width: 100px;
		height: 36px;
		top: 14px;
	}

	.lang-dropdown {

		.faker {
			width: 45px;
			height: 44px;

			& .drop-value, & .drop-handle {height: 44px;}

			.drop-value {
				line-height: 44px;
				font-size: 16px;
				letter-spacing: -0.03px;
			}

			.drop-selection {top: 44px;}
		}
	}

}