/*******************************************************************************
* INTRO SECTION
*******************************************************************************/
.intro-section{
    padding: 145px 0 101px;
    color: $brand-color;

    .saperator{
        margin: 16px 0 26px;
    }
    h2{
        font-weight: $medium;
        font-size: 50px;
        letter-spacing: -0.09px;
    }
    p{
        font-size: 30px;
        letter-spacing: -0.05px;
        line-height: 45px;
        margin: 0 0 46px;
    }
    strong{
        @include inline-block;
        width: 100%;
    }
    span{
        color: $brand-color;
    }
}
@include breakpoint-min-max(992,1199){
    .intro-section{
        p {
            font-size: 24px;
            line-height: 39px;
            margin: 0 0 40px;
        }
    }
}
@include breakpoint-min-max(768,991){
    .intro-section{
        padding: 80px 0 60px;
        h1 {
            margin: 0 0 30px;
        }
        p{
            font-size: 20px;
            line-height: 31px;
            margin: 0 0 20px;
        }
    }
}
@include breakpoint-max(767){
    .intro-section{
        padding: 17px 0;
        .saperator {
            margin: 16px 0 18px;
        }
        h1{
            font-size: 24px;
            letter-spacing: -0.04px;
            line-height: 25px;
            margin: 0 0 25px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03px;
            margin: 0 0 15px;
        }
    }
}