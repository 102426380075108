/*******************************************************************************
* TEAM
*******************************************************************************/
.team-section {
    padding: 76px 0;
    h1 {
        margin: 20px 0 95px;
    }
}
.team-block{
    color: $black;
    width: 100%;
    display: table;
    position: relative;
    padding-left: 262px;
    margin-bottom: 200px;
    &:hover{
        .team-img{
            box-shadow: 0 0 4px rgba(0,0,0,0.4); 
        }
    }
    @include breakpoint-min(767){
        &:last-child {
            margin: 0 0 104px;
        }
        &.right-aligned{
            padding-left: 0;
            padding-right: 262px;
            .team-img-wrap {
                left: inherit;
                right: 0;
            }
            .team-content {
                padding: 30px 128px 30px 99px;
            }
            .designation {
                left: inherit;
                right: 0;
                text-align: right;
            }
        }
    }
    h2{
        color: inherit;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: -0.05px;
        margin: 20px 0 10px;
        max-width: 570px;
    }
    address{
        color: inherit;
        font-size: 18px;
        line-height: 30px;
    }
    a{
        color: $brand-color;
    }
    &.team-block-xl{
        margin-bottom: 100px;
    }
}
.team-content{
    display: table-cell;
    font-weight: 400;
    height: 497px;
    padding: 30px 99px 30px 128px;
    vertical-align: middle;
    width: 100%;
    color: $brand-color;
    .saperator{
        margin: 20px 0 23px;
        width: 164px;
    }
    p {
        font-size: 18px;
        letter-spacing: -0.05px;
        line-height: 30px;
        margin: 0 0 30px;
        &:last-child{
            margin: 0;
        }
    }
}
.team-block-xl{
    .team-content{
        height: 598px;
        padding: 30px 50px 30px 324px;
    }
}

.sl-page    {
    .contact-info   {
        strong  {
            display: none;
        }
    }
}

.contact-info {
  a {
    color: $secondary-color;
  }
}

.vat-info {
  a {
    color: $brand-color;
  }
}

.contact-info,.vat-info{
    margin: 18px 0 25px;
    color: inherit;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.05px;
    strong{
        max-width: 150px;
        float: left;
        width: 30%;
        font-weight: $book;
    }
    p{
        margin: 0;
        line-height: inherit;
    }


}
.vat-info{
    strong{
        max-width: 270px;
        width: 41.2%;
    }
    p{
        margin: 0 0 30px;
    }
    a{
        margin-right: 0;
        margin-bottom: 5px;
        display: block;
        font-size: 20px;
        font-weight: $medium;
        line-height: 25px;
        letter-spacing: -0.04px;
    }
}
.map-link{
    margin: 9px 0;
    font-weight: $medium;
    @include inline-block;
    i{
        margin-right: 18px;
        position: relative;
        float: left;
    }
}
.designation{
    left: 0;
    margin: 29px 0 0;
    max-width: 250px;
    position: absolute;
    top: 100%;
    .saperator {
        margin: 0 0 22px;
        width: 165px;
        @include inline-block;
    }
    strong,p{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    strong,b{
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.04px;
    }
    p{
        line-height: 20px;
        color: $light-grey;
        margin: 0;
    }
}
.team-img-wrap{
    position: absolute;
    left: 0;
    top: 50%;
    @include translate(0,-50%);
}
.team-img{
    background-color: $black;
    @include sqarebox(357);
    @include bg-img;
    @include default_transition;
}
.team-block-xl{
    .team-img{
        @include sqarebox(458);
    }
}
@include breakpoint-min-max(992,1199){
    .team-section {
        padding: 76px 0 30px;
    }
    .team-block{
        .team-content{
            padding: 30px 60px 30px 138px;
        }
        &.right-aligned{
            .team-content{
                padding: 30px 138px 30px 60px;
            }
        }
    }
    .team-block-xl{
        .team-img{
            @include sqarebox(357);
        }
        .team-content {
            height: 528px;
        }
    }
}
@include breakpoint-min-max(768,991){
    .team-section {
        h1 {
            margin: 0 0 80px;
        }
    }
    .team-img{
        @include sqarebox(300);
    }
    .team-block{
        margin-bottom: 150px;
        padding-left: 250px;
        &.right-aligned{
            padding-right: 250px;
            .team-content{
                padding: 30px 68px 30px 25px;
            }
        }
        h2 {
            font-size: 25px;
            line-height: 30px;
            margin: 10px 0;
        }
        address {
            font-size: 16px;
            line-height: 25px;
        }
    }
    .team-content{
        height: 400px;
        padding: 30px 25px 30px 68px;
        p{
            font-size: 16px;
            letter-spacing: 0;
            line-height: 28px;
        }
    }
    .designation{
        b,strong{
            font-size: 18px;
            line-height: 25px;
        }
        p{
            line-height: 22px;
        }
        .saperator {
            margin: 0 0 10px;
            width: 155px;
        }
    }
    .team-block-xl{
        .team-img{
            @include sqarebox(300);
        }
        .team-content {
            height: 460px;
            padding: 30px 5px 30px 78px;
        }
        &:last-child{
            margin: 0 0 30px;
        }
    }
    .vat-info{
        strong {
            width: 100%;
        }
        p {
            margin: 0 0 14px;
        }
        a {
            font-size: 16px;
            line-height: 20px;
            margin-right: 50px;
        }
    }
}

@include breakpoint-max(767){
    .team-section {
        padding: 30px 0;
        h1 {
            margin: 10px 0 40px;
        }
    }
    .team-img{
        @include sqarebox(170);
        @include inline-block;
    }
    .team-block-xl{
        .team-img{
            @include sqarebox(170);
        }
    }
    .team-block {
        display: block;
        margin-bottom: 40px;
        padding-left: 0;
        h2{
            font-size: 20px;
            letter-spacing: -0.04px;
            line-height: 25px;
            margin: 15px 0 20px;
            max-width: 250px;
        }
        address {
            color: inherit;
            font-size: 14px;
            line-height: 20px;
        }
        &.team-block-xl {
            margin-bottom: 40px;
            .team-content {
                height: auto;
                padding: 15px;
            }
        }
    }
    .map-link {
        i {
            margin-right: 9px;
            top: 1px;
        }
    }
    .team-img-wrap {
        display: block;
        left: 0;
        margin: 0 auto;
        position: relative;
        top: 0;
        width: 100%;
        text-align: center;
        @include translate(0,0);
    }
    .team-content {
        display: block;
        height: auto;
        margin: 15px 0 0;
        padding: 15px;
        .saperator {
            margin: 10px 0;
            width: 101px;
        }
        p{
            font-size: 14px;
            letter-spacing: -0.03px;
            line-height: 20px;
        }
    }
    .designation {
        left: 0;
        margin: 15px 0;
        max-width: 100%;
        position: relative;
        top: 0;
        .saperator {
            margin: 3px 0 0;
            width: 85px;
        }
        p,strong,b{
            @include  inline-block;
            width: 100%;
        }
        strong,b{
            font-size: 16px;
            letter-spacing: -0.03px;
            line-height: 20px;
        }
    }
    .vat-info {
        margin: 18px 0 10px;
    }
    .contact-info{
        strong {
            width: 34%;
        }
    }
    .vat-info {
        strong {
            max-width: 100%;
            width: 100%;
            @include inline-block;
        }
        p {
            margin: 0 0 15px;
        }
        a {
            font-size: 14px;
            line-height: 20px;
            margin-right: 30px;
        }
    }
}