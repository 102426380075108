/*******************************************************************************
* CONTACT SECTION
*******************************************************************************/
.contact-section{

    padding: 33px 0 24px;
    h2{
        margin: 20px 0 50px;
        color: $black;
    }
}
.textarea {
    margin-top: 38px;
}
.contact-form {
    padding: 60px 20.5%;
    position: relative;
    .saperator {
        margin: 20px 0 25px;
        width: 164px;
    }
    .btn{
        margin: 12px 0 5px;
        min-width: 159px;
    }

    .thank-you-form {
        display: none;
    }
}
@include breakpoint-min-max(768,991){
    .contact-form {
        padding: 60px 10%;
    }
}
@include breakpoint-max(767){
    .contact-section {
        padding: 0 0 20px;
    }
    .contact-form {
        padding: 10px 15px;
        .saperator {
            margin: 20px 0 10px;
            width: 84px;
        }
        h2 {
            margin: 10px 0 15px;
        }
    }
}

.form-group {
    &.has-error {
        .checkbox {
            span {
                color: red;

                &:before {
                    border: 1px solid red;
                }
            }
        }
    }
}

.checkbox	{

    input[type=checkbox] {
        position:absolute;
        z-index:-1000;
        left:-1000px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height:1px;
        width:1px;
        margin:-1px;
        padding:0;
        border:0;
    }

    label {
        padding-left: 0;
    }

    span {
        display:inline-block;
        line-height:30px;
        background-repeat:no-repeat;
        background-position: 100% 0;
        font-size: 20px;
        color: #333;
        font-weight: 400;
        vertical-align:middle;
        cursor:pointer;
        width: 100%;
        margin-bottom: 10px;
        float: left;
        position: relative;
        padding-left: 50px;
        text-transform: none;

        @include breakpoint-max(768) {
            font-size: 15px;
            line-height: 22px;
        }

        &:before	{
            content:"";
            position: absolute;
            width: 32px;
            height: 32px;
            border: 1px solid #dfdfdf;
            background-color: white;
            left: 0;
            top: 0;
        }

        &:after	{
            content:"";
            position: absolute;
            background-image:url('../img/check.svg');
            width: 26px;
            height: 20px;
            background-size: 26px 20px;
            background-repeat: no-repeat;
            left: 4px;
            top: 6px;
            transform: scale(0, 0);
            -webkit-transform: scale(0, 0);
            @include transition(all 0.2s ease-in-out);
        }
    }

    input[type=checkbox]:checked + span {
        &:after	{
            transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
        }
    }

}

.error-msg  {
    font-weight: $book;
    font-size: 20px;
    line-height: 40px;
    margin: 0 0 11px;
    letter-spacing: -0.04px;
    color: red;
}

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label  {
    color: red;
}

.has-error .form-control    {
    border-color: red;
}