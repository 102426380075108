.modal-content  {
    table {
        width: 100%;
        overflow-x: auto;
        margin: 3px 0 35px;
        display: block;
    }
}


/*******************************************************************************
* BLOG INSIDE
*******************************************************************************/
.blog-inside-section {
    padding: 38px 0;
    aside {
        padding: 26px 0;
    }
}
.blog-inside-content-area{
    .blog-date {
        margin: 16px 0 32px;
    }
    p{
        margin: 0 0 43px;

        a {
            color: $secondary-color;
        }
    }
    .simple-quote{
        p{
            margin: 0 0 9px;
        }
    }
    figure {
        margin: 46px 0 52px;
    }
    h3{
        margin: 48px 0 16px;
    }
    h4{
        margin: 48px 0 16px;
    }
    .read-more{
        @include inline-block;
        margin: 55px 0 0;
    }

    table {
        width: 100%;
        overflow-x: auto;
        margin: 3px 0 35px;
        display: block;
    }
}
.blog-inside-banner-img {
    margin: 0 0 76px;
}
@include breakpoint-min(992){
    aside{
        .sidebar-block.category-listing{
            margin: 0 0 55px;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}
@include breakpoint-min-max(768,991){
    .blog-inside-section {
        padding: 30px 0;
        aside{
            padding: 0;
        }
    }
    .blog-inside-banner-img {
        margin: 0 0 30px;
    }
    .blog-inside-content-area {
        margin: 40px 0 0;
        .read-more {
            margin: 20px 0 0;
        }
    }
}
@include breakpoint-max(767){
    .blog-inside-section {
        padding: 19px 0;
        aside {
            padding: 15px 0 0;
        }
    }
    .blog-inside-banner-img {
        margin: 0;
    }
    .blog-inside-content-area {
        .blog-date {
            margin: 0 0 26px;
        }
        p {
            margin: 0 0 30px;
        }
        figure {
            margin: 30px 0;
        }
        h3,h4{
            margin: 30px 0 15px;
        }
        .read-more {
            margin: 20px 0 0;
        }
    }
}
