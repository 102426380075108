/*******************************************************************************
* PUBLICATION AND PRESENTATIONS
*******************************************************************************/

.knowledge-modals-content   {
    li {
        position: relative;
        padding-left: 98px;
        padding-bottom: 42px;
        list-style: none;
        color: $black;
        font-size: 24px;
        line-height: 30px;

        &:before{
            position: absolute;
            content: '';
            top: 6px;
            left: 26px;
            border-radius: 50%;
            @include sqarebox(21);
            background-color: $secondary-color;
        }
        &:after{
            width: 5px;
            height: 100%;
            left: 34px;
            top: 12px;
            content: '';
            position: absolute;
            background-color: $secondary-color;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }

        strong,span{
            width: 100%;
            @include inline-block;
        }
        strong{
            font-weight: $medium;
        }
        span{
            color: $light-grey
        }
    }

    p {
        margin: 0 0 11px;
        font-size: inherit;
        line-height: inherit;

        a {
            display: block;
            position: relative;
            padding-left: 98px;
            padding-bottom: 32px;
            border-bottom: 2px solid #f2f2f2;

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                left: 19px;
                background-image: url(../img/download-icon.svg);
                width: 29px;
                height: 32px;
                background-position: center center;
                background-repeat: no-repeat;

                @include breakpoint-max(767)    {
                    background-size: contain;
                    height: 13px;
                    width: 19px;
                }
            }
        }

        strong,span{
            width: 100%;
            @include inline-block;
        }
        strong{
            font-weight: $medium;
        }
        span{
            color: $light-grey
        }

        q {
            display: block;
            position: relative;
            padding-left: 98px;
            padding-bottom: 32px;
            border-bottom: 2px solid #f2f2f2;

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                left: 19px;
                background-image: url(../img/doc-icon.svg);
                width: 29px;
                height: 32px;
                background-position: center center;
                background-repeat: no-repeat;

                @include breakpoint-max(767)    {
                    background-size: contain;
                    height: 13px;
                    width: 19px;
                }
            }

            &:after {
                content: none;
            }
        }
    }
}


.modal-content{
    .presentation-block{
        p{

        }
    }
}
.publications-wrapper{
    .presentation-block{
        margin: 0 0 44px;
        border-bottom: 2px solid #f2f2f2;
        padding-right: 40px;
        padding-bottom:32px;
        &:before,&:after{
            display: none;
        }
        &:last-child{
            border-bottom: none;
            margin: 0;
        }
    }
    .icon{
        left: 19px;
        position: absolute;
        top: 9px;
    }
}
@include breakpoint-min-max(768,1199){
    .knowledge-modals-content {
        font-size: 20px;
    }
}
@include breakpoint-max(767){
    .knowledge-modals-content{
        li {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.03px;
            padding: 0 0 10px 30px;

            margin: 0 0 22px;
            padding-bottom: 8px;
            padding-right: 10px;

            &:before{
                left: 9px;
                top: 1px;
                @include sqarebox(12);
            }
            &:after{
                left: 14px;
                top: 12px;
                width: 2px;
            }
        }
    }

    .publications-wrapper {
        .icon {
            left: -2px;
            top: 3px;
        }
    }
}