/*******************************************************************************
* BLOG GRID SECTION
*******************************************************************************/
.blog-grid-section{
    padding:51px 0 130px;
    &:hover{
        .saperator{
            width: 50px;
        }
    }
    .btn {
        margin: 110px 0 70px;
        border: 1px solid $secondary-color;
        color: $secondary-color;

      &:hover {
          background-color: $secondary-color;
          color: white;
      }
    }
}
.blog-grid-wrapper{
    margin: 42px 0 0;
    h1 {
        margin: 20px 0 95px;
    }
    .row{
        margin: 0;
    }
    [class*='col-']{
        padding: 0;
    }
}
.blog-grid-block{
    color: $white;
    position: relative;
    overflow: hidden;
    height: 480px;
    h2{
        color: inherit;
        font-size: 26px;
        letter-spacing: -0.07px;
        line-height: 32px;
        margin: 56px 0;
        padding: 0 38px;
        position: relative;
        z-index: 2;
    }
    .blog-date{
        color: inherit;
        bottom: 38px;
        font-size: 18px;
        left: 0;
        letter-spacing: -0.05px;
        line-height: 30px;
        padding: 0 38px;
        position: absolute;
        z-index: 2;
        font-weight: $light;
    }
    &:before,.blog-grid-img{
        @include absoluted-fullwidth;
    }
    &:before{
        content: '';
        z-index: 1;
        background-color: rgba(0,0,0,0.4);
        pointer-events: none;
        @include transition(all, 0.4s, ease);
    }
    &:hover{
        .blog-grid-img{
            @include scale(1.2);
        }
        &:before{
            background-color: rgba(0,0,0,0.8);    
        }
    }
    .blog-grid-img{
        z-index: 0;
        pointer-events: none;
        @include bg-img;
        @include transition(all, 10s, linear);
        @include scale(1);
    }
}
@include breakpoint-min-max(1200,1400){
    .blog-grid-section {
        padding: 40px 0;
    }
    .blog-grid-block {
        height: 350px;
        h2 {
            font-size: 21px;
            line-height: 28px;
            margin: 36px 0;
            padding: 0 28px;
        }
        .blog-date{
            padding: 0 28px;
        }
    }
}
@include breakpoint-min-max(992,1199){
    .blog-grid-section {
        padding: 40px 0;
    }
    .blog-grid-block{
        height: 280px;
        h2 {
            font-size: 20px;
            line-height: 27px;
            margin: 20px 0;
            padding: 0 20px;
        }
        .blog-date{
            padding: 0 20px;
            bottom: 18px;
        }
    }
}
@include breakpoint-min-max(768,991){
    .blog-grid-section {
        padding: 40px 0 0;
    }
    .blog-grid-wrapper {
        margin: 0;
        h1 {
            margin: 20px 0 60px;
        }
    }
    .blog-grid-block {
        height: 370px;
        h2 {
            font-size: 22px;
            letter-spacing: 0;
            line-height: 30px;
            margin: 30px 0;
            padding: 0 30px;
        }
        .blog-date{
            padding: 0 30px;
        }
    }
}
@include breakpoint-max(767){
    .blog-grid-wrapper{
        margin: 5px 0 0;
        h1 {
            margin: 40px 0;
        }
    }
    .blog-grid-section{
        padding: 0;
        .headline-block{
            h2{
                margin: 17px 0 0;
                max-width: 280px;
            }
        }
        .btn{
            margin: 40px 0 80px;
        }
    }
    .blog-grid-block{
        height: auto;
        padding-bottom: 47%;
        h2 {
            font-size: 14px;
            letter-spacing: -0.04px;
            line-height: 17px;
            margin: 5px 0;
            padding: 12px 5px 12px 13px;
        }
        .blog-date{
            padding: 0 12px;
            bottom: 5px;
            font-size: 12px;
            line-height: 12.6px;
            letter-spacing: -0.03px;
        }
    }
}