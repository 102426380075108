//*******************************************************************************
// LANDING PAGE
//*******************************************************************************


.landing-section {
    padding-top: 110px;

  @include breakpoint-max(991) {
    padding-top: 50px;
  }

    .headline-block {
        padding: 0;

      .saperator {
        margin-top: 0;
      }

      h1 {
        margin-bottom: 30px;
      }
    }

  .landing-why {
    padding-bottom: 50px;
    //border-bottom: 1px solid #e8e8e8;

    @include breakpoint-max(767) {
      padding-bottom: 32px;
    }

    p {
      color: $brand-color;
      font-size: 30px;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: -0.05px;
      margin-bottom: 10px;

      @include breakpoint-max(767) {
        font-size: 22px;
        line-height: 30px;
      }

      small {
        font-size: 20px;
        letter-spacing: -0.04px;

        @include breakpoint-max(767) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    h3 {
      color: $secondary-color;
      font-size: 40px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: -0.07px;
      margin-bottom: 0;

      @include breakpoint-max(767) {
        font-size: 32px;
      }
    }

    .btn {
      margin-top: 30px;
    }
  }

  .landing-what {
    padding-bottom: 50px;
    //border-bottom: 1px solid #e8e8e8;
    padding-top: 30px;

    @include breakpoint-max(767) {
      padding-bottom: 32px;
      padding-top: 10px;
    }

    h2 {
      color: $brand-color;
      font-size: 40px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: -0.07px;
      margin-bottom: 20px;

      @include breakpoint-max(767) {
        font-size: 32px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.04px;
      color: $brand-color;
      margin-bottom: 30px;

      @include breakpoint-max(767) {
        font-size: 16px;
      }

      strong {
        font-weight: 500;
        color: $secondary-color;
      }
    }

    .what-list {
        @include flexbox();
        @include flex-wrap(wrap);
      padding: 0;
      margin-bottom: 34px;
      margin-left: -8px;
      margin-right: -8px;

      li {
        width: 25%;
        padding: 0 8px;
        margin-bottom: 16px;
        list-style: none;

        @include breakpoint-max(767) {
          width: 50%;
        }

        span {
          background-color: #e8e8e8;
          height: 94px;
          display: block;
          text-align: center;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          padding: 12px;
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.04px;
          color: $brand-color;
          @include transition(all 0.25s ease);
          position: relative;

          @include breakpoint-max(1199) {
            font-size: 15px;
          }

          &.has-link {
            background-color: $secondary-color;
            color: white;

            &:hover {
              background-color: white;

              a {
                color: $secondary-color;
              }
            }
          }
        }

        a {
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 12px;
          color: white;
          @include transition(all 0.25s ease);
        }
      }
    }
  }

  .landing-references {
    padding-bottom: 110px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 30px;

    @include breakpoint-max(991) {
      padding-bottom: 50px;
    }

    @include breakpoint-max(767) {
      padding-bottom: 32px;
      padding-top: 10px;
    }

    h2 {
      color: $brand-color;
      font-size: 40px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: -0.07px;
      margin-bottom: 20px;

      @include breakpoint-max(767) {
        font-size: 32px;
      }
    }

    h3 {
      color: $brand-color;
      font-size: 30px;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: -0.05px;
      margin-bottom: 25px;

      @include breakpoint-max(767) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.04px;
      color: $brand-color;
      margin-bottom: 30px;

      @include breakpoint-max(767) {
        font-size: 16px;
      }

      strong {
        font-weight: 500;
        color: $secondary-color;
      }
    }

    .btn {
      margin-top: 50px;
    }

    .testimonials-slider-landing-init.owl-carousel {

      .quote-block{
        width: 100%;
        float: left;
        padding: 15px 15px 0;

        p {
          color: $brand-color;
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: -0.05px;
          margin-bottom: 20px;
          font-style: normal;
        }

        cite {
          color: $brand-color;
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: -0.05px;
        }
      }

      .quote-img {
        margin-bottom: 25px;
        overflow: visible;
      }

      .author-img {
        position: relative;
      }

      .company-img {
        border: 5px solid $secondary-color;
        border-radius: 100%;
        width: 70px;
        height: 70px;
        background: white;
        position: absolute;
        top: -17px;
        right: -30px;
        z-index: 50;
        overflow: hidden;


        img {
          max-width: 80%;
          max-height: 80%;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          @include translate(-50%, -50%);
        }
      }

      .owl-dots {
        padding: 30px 0 0;
        text-align: center;
        width: 100%;
        @include inline-block;
      }

      .owl-dot{
        width: 21px;
        height: 21px;
        margin: 0 5px;
        border-radius: 50%;
        border: 2px solid $brand-color;
        //background-color: rgba($brand-color, 0.5);

        @include inline-block;
        @include default_transition;
        &.active,&:hover{
          background-color: $brand-color;
        }
      }

    }
  }

  .sidebar-block.landing-sidebar.grey-bg {
    padding: 32px 32px 10px;

    @include breakpoint-max(600) {
      padding: 16px 16px 1px;
    }

    @include breakpoint-max(991) {
      background-color: #e8e8e8 !important;

    }

    .wrap {
      @include breakpoint-max(991) {
        @include flexbox();
        @include justify-content(space-between);

        @include breakpoint-max(600) {
          display: block;
        }
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      text-transform: uppercase;
      letter-spacing: -0.04px;
      margin-bottom: 20px;

      @include breakpoint-max(991) {
        border-bottom: 0;
        padding: 0;
      }

      &:before {
        @include breakpoint-max(991) {
          content: none;
        }
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.04px;
      margin-bottom: 20px;

      //@include breakpoint-max(991) {
      //  //display: none;
      //  color: transparent;
      //}
      //
      //@include breakpoint-max(600) {
      //  display: none;
      //}
    }

    span {
      background-color: $secondary-color;
      display: block;
      padding: 18px;
      margin-bottom: 22px;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.03px;
      color: white;

      a {
        color: white;
      }
    }
  }

  .sidebar-block.landing-sidebar {

    @include breakpoint-max(991) {
      margin-bottom: 32px;
    }


    .separator {
      margin-bottom: 20px;
      margin-top: 0;
    }

    h4 {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.04px;
      margin-bottom: 20px;
    }

    p {
      color: $brand-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.03px;
      margin-bottom: 20px;
    }

    li {
      color: $brand-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.03px;
      margin-bottom: 0;
    }
  }
}