/*******************************************************************************
* PARTNERS SECTION
*******************************************************************************/
.partners-section{
    padding: 100px 0 150px;
    text-align:center;
    h2{
        color: $black;
        font-size: 50px;
        font-weight: $medium;
        letter-spacing: -0.09px;   
        line-height: 50px;
        max-width: 750px;
        width: 100%;
        margin: 12px 0 94px;
        @include inline-block;
    }
}
.partners-block{
    text-align: center;
    position: relative;
    width: 33.33%;
    padding-bottom: 33.33%;
    overflow: hidden;
    float: left;
    a{
        @include absoluted-fullwidth;
    }
    &:before,&:after{
        content: '';
        background-color: rgba(0, 0, 0, 0.5);
        pointer-events: none;
        z-index: 1;
        @include absoluted-fullwidth;
        @include transition(all,0.3s,ease);
    }
    &:after{
        z-index: 3;
        opacity: 0;
        @include transition(all,0.3s,ease);
        background-color: rgba(0, 0, 0, 0.7);
    }
    .partners-bg-img{
        @include bg-img;
        @include absoluted-fullwidth;
        pointer-events: none;
        z-index: 0;
        @include transition(all,5s,linear);
    }
    .partner-logo{
        z-index: 2;
        text-align: center;
        padding: 0 15px;
        width: 100%;
        max-height: 250px;
        max-width: 250px;
        @include absoluted-center-center;
    }
    &:hover{

        .partners-bg-img{
            @include scale(1.2);
        }
        &:after{
            opacity: 1;
        }
        .parner-hover{
            opacity: 1;
            top: 0;
        }

    }

    &.no-subpage    {
        &:hover {
            .partners-bg-img{
                @include scale(1);
            }
            &:after{
                opacity: 0;
            }
            .parner-hover{
                opacity: 0;
                top: 0;
            }
        }
    }
}
.parner-hover{
    color:$white;
    padding: 43px 49px;
    position: absolute;
    text-align: left;
    z-index: 4;
    left: 0;
    top: 30px;
    opacity: 0;
    @include transition(all,0.4s,ease);
    h4{
        color: inherit;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.07px;
    }
    p{
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.05px;
    }
}
@include breakpoint-min-max(992,1199){
    .partners-section {
        padding: 40px 0 90px;
    }
    .partners-block{
        .partner-logo {
            padding: 0 45px;
        }
    }
}
@include breakpoint-min-max(768,991){
    .partners-section {
        padding: 50px 0 100px;
        h2 {
            font-size: 40px;
            line-height: 46px;
            margin: 0 0 60px;
            max-width: 610px;
        }
    }
    .partners-block {
        .partner-logo {
            padding: 15%;
        }
    }
    .parner-hover {
        padding: 15px 25px;
        h4 {
            font-size: 22px;
            line-height: 26px;
        }
    }
}
@include breakpoint-max(767){
    .partners-section{
        padding: 30px 0 60px;
        h2{
            font-size: 24px;
            letter-spacing: -0.04px;
            line-height: 25px;
            margin: 10px 0 30px;
        }
    }
    .partners-block {
        padding-bottom: 50%;
        width: 50%;
        .partner-logo {
            padding: 15%;
        }
    }
    .parner-hover {
        padding: 15px;
        h4 {
            font-size: 18px;
            line-height: 20px;
            margin: 0 0 5px;
        }
        p{
            font-size: 12px;
            letter-spacing: -0.03px;
            line-height: 16px;
        }
    }
}

