/*******************************************************************************
* BLOG SIDEBAR SECTION
*******************************************************************************/

.sidebar-collapsible-area   {
    a {
        cursor: pointer !important;
    }
}

.sidebar-block{
    margin: 0 0 4px;
    padding: 15px 31px;
    &.category-listing {
        padding-top: 104px;
        padding-bottom: 84px;
    }
    &.grey-bg{
        margin: 0 0 37px;
        padding: 37px 35px 33px 35px;
        img{
            margin: 3px 0 33px;
        }

        p {
            color: #63666a;
        }
    }
    .saperator {
        margin: 18px 0 27px;
        width: 165px;
    }
    h3{
        color: $black;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.04px;
        margin: 0;
    }
    h4{
        color: $defalut-color;
        font-weight: $medium;
        font-size: 16px;
        margin: -1px 0;
        line-height: 30px;
    }
    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.03px;
        margin: 0 0 13px;
        max-width: 230px;
    }
    img {
        margin: -2px 0 19px;
    }
    .auther-name{
        font-size: 20px;
        font-weight: $medium;
        line-height: 25px;
        letter-spacing: -0.04px;
    }
    .auther-designation{
        color: $light-grey;
        margin: -3px 0 29px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.03px;
    }
    .blog-date {
        font-size: 16px;
        font-weight: $book;
        letter-spacing: -0.03px;
        margin: -7px 0 0;
        color: $light-grey;
    }
}
@include breakpoint-min(992){
    .sidebar-collapsible-area{
        display: block !important;
    }
}
@include breakpoint-min-max(992,1199){
    .sidebar-block{
        padding: 20px;
        &.grey-bg{
            margin: 0 0 37px;
            padding: 20px;
        }
        &.category-listing {
            padding-bottom: 30px;
            padding-top: 96px;
        }
    }
}
@include breakpoint-max(991){
    .sidebar-block {
        padding: 0;
        margin: 0;
        &.category-listing {
            padding-bottom: 0;
            padding-top: 0;
        }
        &.grey-bg{
            padding: 0;
            margin: 0;
            background-color: transparent !important;
            img{
                margin: 30px 0 26px;
            }
        }
        img{
            margin: 30px 0 26px;
        }
        h3{
            cursor: pointer;
            position: relative;
            font-weight: $book;
            border-bottom: 2px solid $brand-color;
            &:before{
                background-image: url("../img/select-right-arrow-orange.svg");
                background-position: 50% center;
                background-repeat: no-repeat;
                content: "";
                height: 14px;
                pointer-events: none;
                position: absolute;
                right: 12px;
                top: 13px;
                width: 10px;
            }
        }
        &.sidebar--open{
            h3{
                &:before{
                    height: 10px;
                    right: 9px;
                    top: 14px;
                    width: 14px;
                    background-image: url("../img/select-down-arrow-orange.svg");
                }
            }
        }
        .saperator,.sidebar-collapsible-area{
            display: none;
        }
        .sidebar-collapsible-area{
            padding: 0 10px;
        }
        .auther-name,.auther-designation{
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03px;
        }
        .auther-name{
            margin: 0 0 7px;
        }
        .auther-designation{
            margin: -3px 0 12px;
        }
    }
    aside{
        margin: 0 0 33px;
    }
}
@include breakpoint-min-max(768,991){
    .sidebar-block{
        h3{
            font-size: 18px;
            line-height: 24px;
            padding: 13px 10px;
            &:before{
                top: 18px;
            }
        }
        li {
            margin: 13px 0;
            max-width: 100%;
            padding: 0;
        }
    }
}
@include breakpoint-max(767){
    .sidebar-block{
        h3{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03px;
            padding: 9px 10px;
        }
        li{
            font-size: 14px;
            letter-spacing: -0.03px;
            line-height: 16px;
            margin: 16px 0 15px;
            max-width: 250px;
            padding: 0;
        }
    }
}
