// Gotham Light
@font-face {
    font-family:'Gotham';
    src: url('../fonts/Gotham-Light.eot');
    src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        //url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff'),
        url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

// Gotham Book
@font-face {
    font-family:'Gotham';
    src: url('../fonts/GothamOT-Book.eot');
    src: url('../fonts/GothamOT-Book.eot?#iefix') format('embedded-opentype'),
        //url('../fonts/GothamOT-Book.woff2') format('woff2'),
        url('../fonts/GothamOT-Book.woff') format('woff'),
        url('../fonts/GothamOT-Book.svg#GothamOT-Book') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

// Gotham Medium
@font-face {
    font-family:'Gotham';
    src: url('../fonts/GothamOT-Medium.eot');
    src: url('../fonts/GothamOT-Medium.eot?#iefix') format('embedded-opentype'),
        //url('../fonts/GothamOT-Medium.woff2') format('woff2'),
        url('../fonts/GothamOT-Medium.woff') format('woff'),
        url('../fonts/GothamOT-Medium.svg#GothamOT-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

// Gotham Bold
@font-face {
    font-family:'Gotham';
    src: url('../fonts/GothamOT-Bold.eot');
    src: url('../fonts/GothamOT-Bold.eot?#iefix') format('embedded-opentype'),
        //url('../fonts/GothamOT-Bold.woff2') format('woff2'),
        url('../fonts/GothamOT-Bold.woff') format('woff'),
        url('../fonts/GothamOT-Bold.svg#GothamOT-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

// Gotham Black
@font-face {
    font-family:'Gotham';
    src: url('../fonts/Gotham-Black.eot');
    src: url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        //url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff'),
        url('../fonts/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
}

h1, h2, h3, h4, h5, h6{
    color: $brand-color;
    font-weight: $medium;
}
h1{
    color: $black;
    letter-spacing: -0.09px;
    font-size: 50px;
    line-height: 50px;
    font-weight: $medium;
    sub, sup {
        font-size: 45%;
    }
    sup {
        top: -0.8em;
    }
}
h2{
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.05px;
    margin: 20px 0 15px;
    sup {
        font-size: 18px;
        top: -14px;
    }
}
h3{
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04px;
}
h4{
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.03px;
}
h5{
    font-size: 16px;
    line-height: 28px;
}
h6{
    font-size: 14px;
    line-height: 26px;
}
@include breakpoint-min-max(992,1199){
    h1 {
        font-size: 40px;
    }
}
@include breakpoint-min-max(768,991){
    h1 {
        font-size: 38px;
        line-height: 42px;
    }
}
@include breakpoint-max(767){
    h1{
        font-size: 26px;
        letter-spacing: -0.05px;
        line-height: 26px;
    }
    h2{
        font-size: 20px;
        line-height: 27px;
        sup{
            font-size: 10px;
            top: -8px;
        }
    }
    h3{
        font-size: 18px;
        line-height: 22px;
    }
    h4{
        font-size: 16px;
        line-height: 20px;
    }
}

sup {
    top: -.7em;
    font-size: 50%;
}