/*********************************************************
* PLACEHOLDERS
*********************************************************/

// General extensions
%content {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
}

%readmore {
	position: relative;
	
	&:hover, &:active, &:focus {}

}