/*******************************************************************************
* COL 2 TEXT BLOCK
*******************************************************************************/
.col-2-text-section {
    padding-bottom: 133px;
}
.col-2-text-block{
    margin: 0 0 53px;
    h2{
        color: $defalut-color;
        font-size: 20px;
        letter-spacing: -0.04px;
        line-height: 25px;
        margin: 20px 0 21px;
    }
    p{
        color: $light-grey;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.03px;
    }
    .saperator{
        width: 164px;
        margin: 20px 0 22px;
    }
}
@include breakpoint-min-max(992,1199){
    .col-2-text-section {
        padding-bottom: 50px;
    }   
}
@include breakpoint-min-max(768,991){
    .col-2-text-block {
        margin: 0 0 40px;
        padding-right: 20px;
    }
    .col-2-text-section {
        padding-bottom: 73px;
    }
}
@include breakpoint-max(767){
    .col-2-text-section {
        padding-bottom: 40px;
    }
    .col-2-text-block{
        width: 100%;
        margin: 0 0 10px;
        .saperator{
            width: 101px;
            margin: 8px 0 9px;
        }
        h2 {
            font-size: 18px;
            line-height: 22px;
            margin: 15px 0 10px;
        }
        p{
            font-size: 14px;
            letter-spacing: -0.03px;
            line-height: 20px;
            margin: 0 0 20px;
        }
    }
}