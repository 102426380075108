/*******************************************************************************
* 0.1 - SECTIONS
*******************************************************************************/

$padding_xl: 82px;
$padding_md: 60px;


.quiz-wrapper {
	counter-reset: quiz_counter;
	overflow: hidden;
}


// Text styles
.qtext {
	color: $brand-color;
	position: relative;

	* {
		@extend %inherit_textstyles;
		margin: 0;
	}

	// General text style for paragraphs
	&-general {
		font-family: $defalut-fonts;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.65em;

		// Mobile
		@media screen and (max-width: $bkp_ipad) {
			font-size: 14px;
			padding-left: $padding_md;
		}

	}

	// Intro text style
	&-intro {
		color: $brand-color;
		font-family: $defalut-fonts;
		font-size: 50px;
		font-weight: 500;
		line-height: 1.2em;
		text-transform: uppercase;

		// Desktop
		@include breakpoint-min-max(992,1199){font-size: 40px;}
		
		// Tablet
		@include breakpoint-min-max(768,991){
			font-size: 38px;
		}

		// Mobile and down
		@include breakpoint-max(767){
			font-size: 26px;
			line-height: 1.25em;
		}

	}

	// Headings text style
	&-heading {
		padding-left: $padding_xl;
		font-family: $defalut-fonts;
		counter-increment: quiz_counter;
		font-size: 27px;
		font-weight: 500;
		line-height: 1.4em;
		text-transform: uppercase;
		position: relative;
		margin-bottom: 40px;

		// Tablet
		@media screen and (max-width: $bkp_lg) {font-size: 26px;}

		// Tablet
		@media screen and (max-width: $bkp_ipad) {font-size: 24px;}
		
		// Mobile
		@media screen and (max-width: $bkp_md) {
			font-size: 20px;
			padding-left: $padding_md;
			margin-bottom: 30px;
		}

		// Mobile
		@media screen and (max-width: $bkp_sm) {font-size: 18px;}


		// Circle with numbers
		&:before {
			content: counter(quiz_counter);
			color: $secondary-color;
			border: 5px solid $secondary-color;
			width: 50px;
			height: 50px;
			line-height: 40px;
			text-align: center;
			display: block;
			position: absolute;
			left: 0;
			top: 50%;

			outline: 18px solid transparent;

			@include translate(0,-50%);
			@include border-radius(100%);

			// Tablet
			@media screen and (max-width: $bkp_ipad) {border-width: 4px;}
			
			// Mobile
			@media screen and (max-width: $bkp_md) {
				height: 40px;
				width: 40px;
				line-height: 30px;
				outline-width: 10px;
			}

		}

		// fake backgrounds
		// &:after {
		// 	content: "";
		// 	color: #efefef;
		// 	border: 5px solid currentColor;
		// 	width: 50px;
		// 	height: 50px;
		// 	line-height: 40px;
		// 	text-align: center;
		// 	display: block;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%;

		// 	outline: 18px solid transparent;

		// }

	}

}


// Sections
.quiz-section {
	background: transparent no-repeat scroll center top / cover;
	position: relative;
	min-height: 100vh;
	padding-top: 60px;
	padding-bottom: 60px;

	@include flexbox();
	@include align-items(center);

	// Mobile
	@media screen and (max-width: $bkp_md) {
		min-height: 100vh;
		height: auto;
		padding-top: 40px;
		padding-bottom: 40px;
	}


	//.container {opacity: 0;}


	// Quiz box container
	.quiz-answer {
		position: relative;
		z-index: 2;

		& + .quiz-answer {
			padding-top: 60px;
			
			@media screen and (max-width: $bkp_md) {padding-top: 40px;}

		}

	}


	// Intro section
	&--white-text {

		.qtext-general, .qtext-heading {color: $white;}

		.quiz-select-labels label {
        color: white;

			&.is-active {
				color: $secondary-color;
			}
      }

	}


	// Form section
	&--form {
		z-index: 3;

		.qtext-intro {color: $white;}

		// Canvas
		canvas {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

	}


	// Gray
	&--gray {
      background-color: #f3f1f1;

		.qtext-heading:before {
			background-color: #f3f1f1;
			outline-color: #f3f1f1;
		}

	}
	
	// Yellow
	&--yellow {
		background-color: #8d78f2;

		.qtext-heading:before {
			background-color: #8d78f2;
			outline-color: #8d78f2;
		}

	}
	
	// Blue
	&--blue {
		background-color: #ffcc82;

		.qtext-heading:before {
			background-color: #ffcc82;
			outline-color: #ffcc82;
		}

	}
	
	// Dark gray
	&--dkgray {
      background-color: #8d78f2;

		.qtext-heading:before {
			background-color: #8d78f2;
			outline-color: #8d78f2;
		}

	}
	
	// Light gray
	&--ltgray {
		background-color: #f3f1f1;

		.qtext-heading:before {
			background-color: #f3f1f1;
			outline-color: #f3f1f1;
		}

	}
	
	// Dark blue
	&--dkblue {
		background-color: #0d0240;

		.qtext-heading:before {
			background-color: #0d0240;
			outline-color: #0d0240;
		}

	}


}


// Intro section
.quiz-section--intro {
	position: relative;
	z-index: 2;

	// Mobile
	@media screen and (max-width: $bkp_md) {
		padding-top: 60px;
		padding-bottom: 20px;
	}


	.text {color: $white;}

	.qtext-intro {
		margin-bottom: 8px;
		position: relative;

		h2 {
			color: white;
		}

		// Mobile
		@media screen and (max-width: $bkp_md) {padding-left: $padding_md;}

		// Icon
		&:before {
			background: url(../img/lupa-quiz.svg) no-repeat scroll right top / cover;
			content: "";
			display: block;
			position: absolute;
			left: -48px;
			top: 40px;
			height: 116px;
			width: 182px;

			@include translate(-100%, 0);

			// Mobile
			@media screen and (max-width: $bkp_md) {
				width: 60px;
				height: 36px;
				top: 15px;
				left: 50px;
			}

			// Tablet
			@include breakpoint-min-max(768, 991) {
				height: 90px;
				width: 142px;
			}

		}

	}

}


// Form section
.quiz-section--form {

	.qtext {margin-bottom: 40px;}

	// Image
	.image {
		text-align: center;

		// Desktop
		@media screen and (max-width: $bkp_xl) {margin-bottom: 40px;}
		
		// Tablet
		@media screen and (max-width: $bkp_lg) {
			text-align: left;
			margin-bottom: 0;
		}

		// Mobile
		@media screen and (max-width: $bkp_md) {
			text-align: center;
		}

		img {

			// Tablet 
			@media screen and (max-width: $bkp_lg) {max-width: 160px;}
			
			// Mobile
			@media screen and (max-width: $bkp_lg) {max-width: 120px;}

		}

	}

}


/*******************************************************************************
* 2.0 - SELECT
*******************************************************************************/

.quiz-select {
	padding-left: $padding_xl;
	position: relative;

	@include flexbox();
	@include align-items(stretch);

	// Mobile
	@media screen and (max-width: $bkp_md) {
		padding-left: $padding_md;
	}

}

.quiz-select-radios {
	display: none;

	input {
		display: none;
		appearance: radio;
		-webkit-appearance: radio;
	}

}

// Labels wrapper
.quiz-select-labels {
	display: inline-block;
	vertical-align: middle;
	order: 2;
	padding-left: 30px;

	// Mobile
	@media screen and (max-width: $bkp_md) {
		padding-left: 20px;
	}


	// Label
	label {
		font-family: $defalut-fonts;
		color: $brand-color;
		cursor: pointer;
		font-size: 20px;
		font-weight: 400;
		line-height: 25px;
		display: table;
		margin-bottom: 8px;
		margin-top: 8px;

		@include transition(color 250ms ease);

		// Mobile
		@media screen and (max-width: $bkp_md) {font-size: 16px;}

		// Mobile
		@media screen and (max-width: $bkp_ipad) {
			margin-top: 16px;
			margin-bottom: 16px;
			line-height: 1.2em;
		}


		//& + label {margin-top: 8px;}

		// Hover and active
		&:hover {color: rgba($brand-color, 0.6);}
		&.is-active {color: $brand-color;}

	}

}

// States wrapper
.tsb-casing-wrapper {
	position:relative;
	font-size: 20px;
	padding: 10px 0;
	order: 1;
	min-width: 22px;
	width: 22px;

	@include border-radius(11px);
}

// States background
.tsb-casing {
	font-size: 20px;
	background-color: $white;
	width: 22px;
	height: 100%;

	@include border-radius(11px);
}

// State triggers
.tsb-state {
	background-color: transparent;
	cursor: pointer;
	height:1em;
	left: 1px;
	position: absolute;
	width: 20px;

	@include border-radius(100%);
	@include transition(background-color 250ms ease-in-out);

	&:hover { background-color: rgba($secondary-color, 0.2); }

}

// Active state ball
.tsb-ball {
	background-color: $secondary-color;
	position: absolute;
	vertical-align: central;
	left: 1px;
	height: 1em;
	width: 1em;
	z-index: 2;
	opacity: 0;

	@include border-radius(100%);
	@include scale(0);
	@include transition(top 250ms ease-in-out, transform 250ms ease-in 100ms, opacity 275ms ease-in 120ms);

	&--anim {
		opacity: 1;
		@include scale(1);
	}

}


/*******************************************************************************
* 3.0 - FORM
*******************************************************************************/

// CMS form
.form-cms {
	//display: none;
}



// Quiz form
.quiz-form {
	padding-top: 168px;
	//width: 360px;

	// Desktop
	@media screen and (max-width: $bkp_xl) {padding-top: 100px;}

	// Tablet
	@media screen and (max-width: $bkp_lg) {
		padding-top: 40px;
		margin-top: -120px;
	}

	// Mobile
	@media screen and (max-width: $bkp_md) {
		padding-top: 60px;
		margin-top: 0;
	}

	.input-group {
		margin-bottom: 16px;
		width: 100%;
	}

	input, button {
		color: $white;
		font-family: $defalut-fonts;
		font-size: 20px;
		font-weight: 400;

		// Tablet
		@media screen and (max-width: $bkp_lg) {
			font-size: 18px;
		}

	}

	input {
		background-color: #ffffff;
		border: 1px solid transparent;
		height: 66px;
		line-height: 66px;
		padding: 0 20px;
		width: 100%;
      color: #000000;

		@include transition(border 250ms ease);

		// Tablet
		@media screen and (max-width: $bkp_lg) {
			height: 60px;
			line-height: 60px;
		}

		// Mobile
		@media screen and (max-width: $bkp_md) {
			height: 50px;
			line-height: 50px;
		}


		// Focus effect
		&:focus { border-color: $brand-color; }

	}

	// Placeholder default
	inout::placeholder,
	inout::-moz-placeholder,
	inout::-webkit-input-placeholder,
	inout::-ms-input-placeholder { color: rgba($white, 0.7); opacity: 1; @include transition(opacity 250ms ease); }

	// Placeholder focus
	input:focus::placeholder,
	input:focus::-moz-placeholder,
	input:focus::-webkit-input-placeholder,
	input:focus::-ms-input-placeholder { color: rgba($white, 0); opacity: 0; @include transition(opacity 250ms ease); }

	// Checkbox
	.checkbox {
		background-color: #0d0240;
		outline: 10px solid #0d0240;


		span {
			color: #fff;
			margin-bottom: 0;

			// Tablet
			@media screen and (max-width: $bkp_lg) {
				font-size: 16px;
				line-height: 1.5em;
			}

			// Checkbox square
			&:before {

				// Tablet
				@media screen and (max-width: $bkp_lg) {
					height: 26px;
					width: 26px;
				}

			}

			// Checkbox checked icon
			&:after {

				// Tablet
				@media screen and (max-width: $bkp_lg) {
					top: 3px;
					left: 0;
				}

			}

		}
	}

	// Submit button
	button {
		border: none;
		background-color: transparent;
		float: right;
		width: auto;
		position: relative;
		overflow: hidden;
		//margin-top: 24px;

		span {
			display: block;
			height: 56px;
			line-height: 56px;
			padding: 0 40px;
			text-align: center;
			position: relative;
			z-index: 2;

			// Tablet
			@media screen and (max-width: $bkp_lg) {
				height: 50px;
				line-height: 50px;
			}

		}

		// Background effect
		&:before {
			background-color: $secondary-color;
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 0;
          @include border-radius(5px);

			//@include translate(-100%,41%);
			@include transition(transform 250ms ease-in-out);
		}

		&:after {
			background-color: rgba(255,255,255,0.4);
			content: "";
			position: absolute;
			top: 0px;
			left: 0px;
			width: 0%;
			height: 100%;
		}

		&:hover:after {
			width: 120%;
			background-color: rgba(255,255,255,0);

			@include transition(all 0.3s ease-out);
		}

		//&:hover:before { @include translate(-100%,41%); }

	}


	// Form messages
	.error-msg {
		//padding-left: 50px;
		position: absolute;
		bottom: -0.5em;
		right: 0;
		margin-bottom: 0;
		color: $brand-color;
		
		@include translate(0,100%);
	}

}



// Fake line
.line-start {
	position: absolute;
	left: 20px;
	top: 50%;
	width: 10px;
	height: 0;
	background-color: $secondary-color;
	margin-top: 40px;

	// Mobile
	@media screen and (max-width: $bkp_md) {
		left: 15px;
		margin-top: 30px;
	}
	
	&:not(.is-drawing) {height: 0 !important;}
	&:not(.noanim) {
		@include transition(height 500ms linear);
	}

}


/*******************************************************************************
* 4.0 - THANK YOU SCREEN
*******************************************************************************/
.thank-you-form {

	//height: auto;
	//min-height: 100%;
	padding-top: 220px;
	padding-bottom: 120px;
	//display: block;
	&:not(.is-visible) {
		display: none;
	}
	

	@media screen and (max-width: $bkp_lg) {
		padding-top: 160px;
		padding-bottom: 80px;
	}
	
	@media screen and (max-width: $bkp_md) {
		padding-top: 80px;
		padding-bottom: 60px;
	}

	.qtext-intro {
		margin-bottom: 60px;
		font-size: 40px;

		// Desktop
		@include breakpoint-min-max(992,1199){font-size: 34px;}
		
		// Tablet
		@include breakpoint-max(991){
			font-size: 28px;
			line-height: 1.45em;
			text-align: center;
		}

		// Mobile and down
		@include breakpoint-max(767){
			font-size: 22px;
			margin-bottom: 40px;
			padding-left: 0;
		}

		// Icon
		&:before {

			@include breakpoint-max(991) {
				position: relative;
				left: auto;
				margin-bottom: 40px;
				top: auto;
				display: inline-block;

				@include transform(none);
			}

			@include breakpoint-max(767){
				height: 90px;
				width: 142px;
			}

			@include breakpoint-max(480){
				height: 63px;
				width: 100px;
			}

		}

	}


}


