@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss";
@import "./node_modules/@specto/specto_faker/specto_faker.scss";

/*******************************************************************************
* X.1 - INCLUDES: Files
*******************************************************************************/


$bkp_xxl: 1400px;        // XXL  : 1400px
$bkp_xl: 1200px;         // XL   : 1200px
$bkp_lg: 992px;          // LG   : 992px
$bkp_ipad: 768px;        // iPad : 768px
$bkp_md: $bkp_ipad - 1;  // MD   : 767px
$bkp_sm: 580px;          // SM   : 580px
$bkp_xs: 425px;          // XS   : 425px
$bkp_xxs: 320px;         // XXS  : 320px


// Includes
@import "mixins/mixins";

@import "base/variables";
@import "base/extensions";
@import "base/typography";
@import "base/base";

@import "include/cookies";
@import "include/buttons";
@import "include/general";
@import "include/site-loader";
@import "include/headline-block";
@import "include/intro-section";
@import "include/content-section";
//@import "include/why";
//@import "include/what";
@import "include/col-2-text-block";
@import "include/partners";
@import "include/quote";
@import "include/reference";
@import "include/publications-and-presentations";
@import "include/modal-box";
@import "include/blog-grid";
@import "include/blog-list";
@import "include/blog-inside";
@import "include/blog-sidebar";
@import "include/team";
@import "include/contact";
//@import "include/timeline-slider";
//@import "include/timeline";
@import "include/testimonials";

@import "include/navigation";
@import "include/header";
//@import "include/footer";

@import "include/full-height-section";

@import "include/front_animation";
@import "include/quiz";

@import "include/landing_page";



//*******************************************************************************
// SPECIFIC STYLES FOR THIS PAGE
//*******************************************************************************

// Buttons
.btn {
	&.btn-solid {
		font-size: 16px;
		background-color: #faaa38;

		&:hover,&:focus,&:active{ color: #faaa38; }

	}
}

// Sidebar
.sidebar--landing {
	height: 100%;
	display: flex;
	flex-flow: column;

	&__anchors {
		position: sticky;
		top: 100px;
		@include breakpoint-max(767){ order: 2; }
	}

	&__author {
		@include breakpoint-max(767){ order: 1; }
	}
}

.sidebar--landing__link {
	a {
		display: block;
		&:hover { color: #faaa38; }
	}
}


// Page notice
.page-notice {
	padding: 40px 0 60px;

	a {
		color: #faaa38;

		&:hover,&:focus,&:active{
			text-decoration: underline;
		}

	}

}


@include breakpoint-max(991){
    .sidebar-block.sidebar--open h3:before { top: 22px; }
}
