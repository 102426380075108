/*******************************************************************************
* 0.0 BASE
*******************************************************************************/
html,
body {
    font-size: 100%;
    vertical-align: baseline;
}
body {
    line-height: 1.5;
    color: $defalut-color;
    font-family: $defalut-fonts;
    font-size: $default-font-size;
}
input[type=password],
input[type=text],
input[type=email],
button,
select,
textarea {
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
img {
    height: auto;
    vertical-align: middle;
    max-width: 100%;
}
p{
    line-height: 30px;
    letter-spacing: -0.03px;
}
a {
    color: $defalut-color;
    text-decoration: none;
    @include transition(all,0.2s,ease,0s);
}
:focus {
    outline: none!important;
}
a:focus,
a:hover {
    color: $default-a-color-hover;
    outline: none;
    text-decoration: none;
}
b,
strong {
    font-weight: $medium;
}
input, select, textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -appearance: none;
}
input::-moz-focus-inner,
textarea::-moz-focus-inner {
    border: 0;
    padding: 0;
}
select::-ms-expand { 
    display: none; 
}
input,
select,
textarea {
    &:hover,&:focus,&:active{
        outline: none;       
    }
    border-radius: 0;
}


label[for="checkbox-group-1524746953140"] {
  display: none;
}

label[for="checkbox-group-1524741530659"] {
  display: none;
}

label{
    font-weight: $book;
    font-size: 20px;
    line-height: 40px;
    margin: 0 0 11px;
    letter-spacing: -0.04px;
}
input[type="password"], input[type="text"], input[type="email"], input[type="tel"], input[type="number"], select, textarea {
    font-size: 20px;
    background-color: transparent;
    border: 1px solid #E8E8E8;
    border-radius: 0;
    box-shadow: none;
    height: 67px;
    outline: none;
    padding: 0 15px;
    width: 100%;
    background-color: $white;
    @include default_transition;
    &:focus{
        box-shadow: none;
        border: 1px solid $brand-color;    
    }
}
textarea{
    resize: vertical;
    min-height: 361px;
    padding: 10px 15px;
}
figcaption,caption{
    color: $light-grey;
    margin: 12px 0;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.03px;
}
caption {
    padding: 0;
}
.table-wrapper{
    width: 100%;
    overflow-x: auto;
    margin: 3px 0 35px;
}
table{
    margin: 0;
    width: 100%;
}
td,th{
    color: $black;
    font-size: 14px;
    line-height: 19px;
    padding: 8px 12px 9px;
}
th{
    font-weight: $medium;
    color: $white;
    background-color: $brand-color;
}
td{

    border-right: 2px solid #f2f2f2;
    &:last-child{
        border-right: none;
    }
}
tr{
    background-color: #f9f9f9;
    &:nth-child(even){
        background-color: #ededed;
    }
}
::-moz-selection { /* Code for Firefox */
    color: $white;
    background: $brand-color;
}
::selection {
    color: $white;
    background: $brand-color;
}
::-webkit-input-placeholder {
    opacity: 1;
}
:-moz-placeholder {
    opacity: 1;
}
::-moz-placeholder {
    opacity: 1;
}
:-ms-input-placeholder {
    opacity: 1;
}
@include breakpoint-max(767){
    body,figcaption,caption{
        font-size: 14px;
        line-height: 20px;
    }
    p{
        line-height: 20px;
    }
    .table-wrapper{
        margin: 0 0 30px;
    }
    td, th {
        font-size: 12px;
        line-height: 17px;
        padding: 6px 12px 7px;
    }
    label {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 5px;
    }
    input[type="email"], input[type="number"], input[type="password"], input[type="text"], select, textarea {
        font-size: 14px;
        height: 43px;
    }
    .textarea {
        margin-top: 0;
    }
    textarea {
        min-height: 100px;
        padding: 7px 15px;
    }
}
