/*******************************************************************************
* 0.5 - Site Loader
*******************************************************************************/

.loader-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: $brand-color;
    width: 100%;
    height: 100%;
}

/* Ajax loader */
#ajaxLoader {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba($black, 0.1);
  width: 100%;
  height: 100%;
}

.loader-img{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    width: 180px;
    height: 180px;

    img {
        width: 180px;
    }

    &:before {
        background: url(data:image/svg+xml;base64,#{$logo_normal}) no-repeat scroll center top / 100% auto;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;

        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);

        width: 100%;
        height: 36px;

    }

    .logo-loader {

    }
}
.loader-2 {
    display: block;
    height: 260px;
    left: 50%;
    margin-left: -130px;
    margin-top: -130px;
    position: absolute;
    top: 50%;
    width: 260px;
    -webkit-animation: loader-2-1 3s linear infinite;
    animation: loader-2-1 3s linear infinite;
    span{
        display: block;
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;
        height: 260px;
        width: 260px;
        clip: rect(130px, 260px, 260px, 0);
        -webkit-animation: loader-2-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
        animation: loader-2-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 260px;
            width: 260px;
            border: 3px solid transparent;
            border-top: 3px solid $brand-color;
            border-radius: 50%;
            -webkit-animation: loader-2-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: loader-2-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0; left: 0;
            bottom: 0; right: 0;
            margin: auto;
            height: 260px;
            width: 260px;
            border: 3px solid rgba($secondary-color, 1);
            border-radius: 50%;
        }
    }
}
@-webkit-keyframes loader-2-1 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-1 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@-webkit-keyframes loader-2-2 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-2 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@-webkit-keyframes loader-2-3 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-3 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@include breakpoint-max(767){
    .loader-img {

        width: 120px;
        height: 120px;

        img {
            max-width: 85px;
        }
    }
    .loader-2 {
        height: 160px;
        margin-left: -80px;
        margin-top: -80px;
        width: 160px;
        span{
            height: 160px;
            width: 160px;
            clip: rect(80px, 160px, 160px, 0);
            &:before {
                height: 160px;
                width: 160px;
            }
            &:after {
                height: 160px;
                width: 160px;
            }
        }
    }
}