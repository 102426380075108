/*******************************************************************************
* QUOTE
*******************************************************************************/

.blog-inside-content-area   {
    cite {
        border: none;
        color: $brand-color;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.05px;
        position: relative;
        font-style: italic;
        padding: 0 0 0 94px;
        margin: 0 0 40px;
        display: block;

        &:before{
            content: '';
            position: absolute;
            left: -2px;
            top: 12px;
            width: 40px;
            height: 10px;
            background-color: $brand-color;
        }

        strong{
            font-style: italic;
        }
        p{
            margin: 0 0 20px;
        }
        sup{
            font-size: 43%;
            top: -0.8em;
        }

        em {
            color: $light-grey;
            padding: 0;
        }
    }
}


.quote-block{
    position: relative;
}
blockquote{
    margin: 0;
    border: none;
    color: $brand-color;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.05px;
    padding: 0;
    position: relative;
    font-style: italic;
    strong{
        font-style: italic;
    }
    p{
        margin: 0 0 20px;
    }
    sup{
        font-size: 43%;
        top: -0.8em;
    }
}
.simple-quote{
    margin: 0 0 40px;
    blockquote{
        padding: 0 0 0 94px;
        &:before{
            content: '';
            position: absolute;
            left: -2px;
            top: 12px;
            width: 40px;
            height: 10px;
            background-color: $brand-color;
        }
    }
    cite{
        color: $light-grey;
        padding: 0;
    }
    p{
        margin: 0 0 9px;
    }
}
cite{
    padding: 0 4px;
    font-style: italic;
    color: $defalut-color;
}

.quote-block blockquote {
    color: $brand-color;
}

.quote-img{
    position:relative;
    background-color: $brand-color;
    border: 5px solid $brand-color;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    margin: 0 auto 47px;
    border: 5px solid $secondary-color;
    @include sqarebox(164);
    @include bg-img;
}
@include breakpoint-min(768){
    .quote-leftside-img{
        margin: 19px 0 79px;
        padding-left: 196px;
        position: relative;
        .quote-img {
            left: 0;
            position: absolute;
            //top: 32px;
            top: 10px;
        }
        strong{
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.04px;
            font-style: normal;
            font-weight: $medium;
            @include inline-block;
            margin: 0 0 15px;
        }
        blockquote{
            color: $brand-color;
        }
    }
}
@include breakpoint-max(767){
    blockquote{
        font-size: 14px;
        letter-spacing: -0.03px;
        line-height: 20px;
        margin: 0 0 15px;
    }
    .quote-img {
        //border: 3px solid $brand-color;
        height: 130px;
        margin: 0 auto 30px;
        width: 130px;
    }
    .quote-leftside-img{
        margin: 0 0 50px;
        strong{
            font-style: normal;
            font-weight: $medium;
            @include inline-block;
            margin: 0 0 15px;
        }
        blockquote{
            color: $defalut-color;
        }
    }
    .simple-quote {
        margin: 0 0 30px;
        blockquote {
            padding: 0 0 0 40px;
            &:before {
                height: 6px;
                left: 0;
                top: 6px;
                width: 24px;
            }
        }
    }
}