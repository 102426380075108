/*******************************************************************************
* MODAL BOX
*******************************************************************************/

.modal-dialog{
    max-width: 1138px;
    width: 100%;
    margin: 110px auto;
}
.modal-content {
    padding: 116px 95px 70px;
    box-shadow: none;
    border: 1px solid #969696;
    border-radius: 0;
    color: $brand-color;
    p{
        font-size: 18px;
        letter-spacing: -0.05px;
        line-height: 30px;
        margin: 0 0 30px;
    }

    .btn {
        background: white;

      &:hover {
        background: $secondary-color;
      }
    }
}
.modal-title{
    color: inherit;
    font-size: 50px;
    font-weight: $medium;
    letter-spacing: -0.09px;
}
button.close {
    background-image: url("../img/close-orange.svg");
    background-position: 50% center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    opacity: 1;
    position: absolute;
    right: 49px;
    text-indent: -99999px;
    top: 51px;
    width: 32px;
}
.modal-header {
    margin: 0 0 56px;
    border-bottom: none;
    padding: 0;
}
.modal-body{
    padding: 0;   
}
@include breakpoint-min-max(993,1199){
    .modal-dialog {
        margin: 100px auto;
        max-width: 939px;
    }
    .modal-title {
        font-size: 40px;
        line-height: 40px;
    }
}
@include breakpoint-min-max(768,1199){
    .modal-content {
        padding: 50px 30px;
    }
    .modal-title{
        font-size: 30px;
    }
    button.close {
        height: 24px;
        right: 20px;
        top: 20px;
        width: 24px;
    }
}
@include breakpoint-min-max(768,992){
    .modal-dialog{
        max-width: 722px;
    }
}
@include breakpoint-max(767){
    .modal-dialog {
        margin: 0;
        padding: 15px;
        width: 100%;
    }
    .modal-content {
        overflow: auto;
        padding: 48px 10px 20px;
        p{
            font-size: 14px;
            letter-spacing: -0.03px;
            line-height: 20px;
            margin: 0 0 15px;
        }
    }
    button.close {
        @include sqarebox(16);
        right: 14px;
        top: 17px;
    }
    .modal-header {
        margin: 0 0 20px;
    }
    .modal-title{
        font-size: 24px;
        line-height: 25px;
        letter-spacing: -0.04px;
    }
}


#newsletter_subscription {

    .modal-content {
        padding-bottom: 120px;

        @include breakpoint-max(992) {
            padding-bottom: 50px;
        }
    }

    h2 {
        font-size: 50px;
        text-transform: uppercase;

        @include breakpoint-max(1200) {
            font-size: 40px;
            line-height: 44px;
        }

        @include breakpoint-max(768) {
            font-size: 30px;
            line-height: 36px;
        }
    }

    .nl-subs-modals-content {
        form {
            @include flexbox();

            @include breakpoint-max(992) {
                display: block;
            }
        }
    }

    .form-left-container {
        width: 50%;

        @include breakpoint-max(992) {
            width: 100%;
        }
    }

    .form-right-container {
        width: 50%;
        padding-left: 68px;
        @include flexbox();
        @include align-items(flex-end);
        @include flex-wrap(wrap);

        @include breakpoint-max(992) {
            width: 100%;
            display: block;
            padding-left: 0;
            padding-top: 20px;
        }

        .checkbox {
          span {
            padding-left: 60px;
          }
        }

        .form-group {
            width: 100%;
            margin-bottom: 50px;
        }
    }

    .submit-container {
        text-align: right;
    }
}