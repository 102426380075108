/*******************************************************************************
* HEADLINE BLOCK
*******************************************************************************/

.sl-page    {
    .blog-section   {
        display: none;
    }
}

.headline-section{
    color:$black;
    @include breakpoint-min(1200){
        .headline-block{
            padding: 99px 0 29px;
        }
    }
}
.headline-block{
    padding: 110px 0 80px;
    position: relative;
    z-index: 2;
    width: 100%;
    color:$black;

    .onSiteWrap {
        width: 100%;
    }

    a{
        //display:block;

        &:hover{
            .saperator{
                width: 50px;
            }
        }
    }
    .btn{
        @include inline-block;
    }
    p{
        color: inherit;
        font-size: 30px;
        line-height: 45px;
        letter-spacing: -0.05px;
        font-weight: $book;

        a {
            color: $secondary-color;
        }
    }
    p{
        font-size: 30px;
        margin: 7px 0 35px;
        color: $brand-color;
    }
    .read-more {
        display: inline-block;
        margin: 22px 0 60px;
    }
    h1{
        margin: 20px 0 64px;
    }
    h2,h3{
        letter-spacing: -0.07px;
        color: $brand-color;
        font-size: 40px;
    }
    h2{
        font-weight: $medium;
        line-height: 45px;
        margin: 7px 0 0;
        max-width: 760px;
        width: 100%;
        @include inline-block;
    }
    h3{
        font-weight: $book;
        line-height: 45px;
        margin: 16px 0 0;
    }
    h4{
        letter-spacing: -0.05px;
        font-weight: $light;
        margin: 0 0 13px;
        font-size: 30px;
        text-align: left;
        width: 100%;
        color: $secondary-color;
        @include inline-block;
    }
    .text-center{
        h2{
            text-transform: uppercase;
        }
    }
    ul,li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    ul {
        margin: 51px 0 0;
    }
    li{
        position: relative;
        color: inherit;
        font-size: 24px;
        line-height: 35px;
        padding-left: 37px;
        letter-spacing: -0.04px;
        font-weight: $light;
        &:before {
            background-color: #fff;
            border-radius: 50%;
            content: "";
            height: 6px;
            left: 14px;
            position: absolute;
            top: 16px;
            width: 6px;
        }
    }
}
@include breakpoint-min-max(992,1199){
    .headline-block {
        padding: 90px 0 60px;
        p{
            line-height: 40px;
        }
        h2,h3 {
            color: #000;
            font-size: 32px;
            letter-spacing: 0;
        }
        h2 {
            line-height: 42px;
            max-width: 760px;
        }
    }
}
@include breakpoint-min-max(768,991){
    .headline-block{
        padding: 90px 0 30px;
        h1 {
            margin: 0 0 40px;
        }
        h4{
            font-size: 26px;
            line-height: 30px;
        }
        .read-more {
            margin: 22px 0;
        }
        p {
            font-size: 22px;
            line-height: 36px;
        }
        h2,h3{
            font-size: 30px;
        }
        li {
            font-size: 19px;
            line-height: 33px;
            padding-left: 37px;
            :before {
                left: 14px;
                top: 14px;
            }
        }
    }
}
@include breakpoint-max(767){
    .headline-block{
        padding: 32px 10px 28px 0;
        margin: 0;
        h4{
            font-size: 16px;
            letter-spacing: -0.03px;
            margin: 5px 0 0;
        }
        h1 {
            margin: 15px 0;
        }
        .read-more {
            margin: 10px 0 0;
        }
        h2,h1{
            font-size: 24px;
            line-height: 25px;
            letter-spacing: -0.04px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03px;
        }
        h3{
            font-size: 16px;
            line-height: 17.28px;
            letter-spacing: -0.03px;
        }
        ul {
            margin: 13px 0 0;
        }
        li{
            padding-left: 36px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03px;
            &:before {
                height: 4px;
                left: 11px;
                top: 8px;
                width: 5px;
            }
        }
    }
}