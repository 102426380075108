.btn{
    box-shadow: none;
    outline: none;
    text-align: center;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.04px;
    padding: 9px 34px 10px;
    min-width: 261px;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    font-weight: $medium;
    @include default_transition;
    @include border-radius(5px);
    &:hover,&:focus,&:active{
        color: $white;
        background-color: $secondary-color;
    }
    &.btn-white{
        color: $white;
        border-color: $white;
        &:hover,&:focus,&:active{
            color: $brand-color;
            background-color: $white;
        }
    }
    &.btn-solid{
        background-color: $brand-color;
        color: $white;
        &:hover,&:focus,&:active{
            color: $brand-color;
            background-color: transparent;
        }
    }
    @include breakpoint-max(767){
        font-size: 14px;
        letter-spacing: -0.03px;
        line-height: normal;
        min-width: 137px;
        padding: 11px 18px;
    }
}

.contact-form {
    .btn.btn-solid {
        background-color: $secondary-color;
        border: 1px solid $secondary-color;
        color: $white;
        &:hover,&:focus,&:active{
            color: $secondary-color;
            background-color: transparent;
        }
    }
}
