body {
	overflow-x: hidden;
}

:root {
  --mouse-x: 100px;
  --mouse-y: 250px;
}

.front-mouse-anim {
  width: 150%;
  height: 150%;
  position: absolute;
  left: 50%;
  top: 50%;
  @include translate(-50%, -50%);

	@media screen and (max-width: 1180px) {
		display: none;
	}

	.anim-wrapper {
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		//transform: translate(
		//								calc(calc(var(--mouse-x) / 1.5 * -1) + 120px),
		//								calc(calc(var(--mouse-y) / 2.5 * -1) + 20px)
		//);
	}
}

.full-height-section--video {
	padding-bottom: 0;
	background-color: black;

	@media screen and (max-width: 1180px) {
		background: url(../img/bg.svg) no-repeat center center;
		background-size: cover;
	}

	.video-container {
		height: 100vh;
		overflow: hidden;

		video {
			object-fit: cover;
			min-height: 100vh;
			max-height: 100vh;
		    width: 100vw;
		    position: absolute;
		    top: 50%;
		    right: 0px;
		    
		    @include translate(0px, -50%);
		}

	}


	.wrapper {
		transform-style: preserve-3d;
		margin: 0 auto;
		//width:130vw;
		//height:120vh;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;left: 0;right: 0;bottom: 0;
		margin: auto;
	}

	.wrapper:after {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: -1;
		display: block;
		content: '';
		height: 22px;
		width: 100%;
		//background: url('http://www.kikk.be/2015/files/templates/kikk2015/public/assets/images/floating-card-shadow.png') no-repeat;
		background-size: 100%;
	}


	.bloc {
		height: 100%;
		width: 100%;
		background: url(http://desktopography.net/media/exhibition/2015/fire_fox/large.jpg) no-repeat center top;
		position: relative;
	}

	.content {
		transform: translateZ(80px) scale(1);
		-webkit-transform: translateZ(80px) scale(1);
		height: 360px;
		width: 210px;
		position: absolute;
		margin:auto;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		padding:20px;
		color: #fff;
		z-index: 3;
	}

	.content2 {
		transform: translateZ(40px) scale(1);
		-webkit-transform: translateZ(40px) scale(1);
		background: #FF9D23;
		opacity: 0.4;
		left: -80px;
		padding: 0;
		z-index: 2;
	}

	.content p:nth-of-type(1) {
		font-size:36px;
		line-height:60px;
		position: absolute;
		bottom:70px;
	}
	.content p:nth-of-type(2) {
		position: absolute;
		bottom:40px;
	}

}


/*******************************************************************************
* 0.1 - FRONT PAGE ANIMATIONS
*******************************************************************************/

$letter_delay: 2400ms;
$letter_delay_i: 2600ms;

$letter_delay2: 4250ms;
$middledelay: 2000ms;

$txt_left_delay: 2500ms;
$txt_right_delay: 5000ms;

.frontanim {
	position: absolute;
	top: 25%;
	width: 100%;
	z-index: 9;

	// Mobile
	@media screen and (max-width: 767px) {
		@include scale(0.6)
	}
}

.animtxt {

	h1 {
		text-align: center;
		font-size: 0;
		margin: 0;
		height: 80px;

		display: flex;
		justify-content: center;
		align-items: center;

	}

	.txt {
		display: inline-block;
		vertical-align: top;
		font-size: 80px;
		color: white;
		text-transform: uppercase;
		line-height: 1em;
		font-weight: 900;
		letter-spacing: -1pt;

		position: relative;
		z-index: 2;

		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased !important;
		-moz-font-smoothing: antialiased !important;
		text-rendering: optimizelegibility !important;

		em {
			font-style: normal;
			position: relative;
		}

	}

	.spanmeshivers {
		position: absolute;
		top: 0;

		@include translate(-100%, 0);
		@include transition(transform 0 ease-in-out);

		.is-loaded & {
			@include transition(transform $txt_left_delay ease-in-out);
			@include translate(0, 0);
		}

	}

	.txt-proper {
		opacity: 0;
		@include transition(opacity 0ms ease-in 0);

		.is-loaded & {
			opacity: 1;
			@include transition(opacity 0ms ease-in $txt_right_delay);
		}

	}

	// Left text
	.txt-left {
		position: relative;
		z-index: -1;

		em {
			color: $secondary-color;
			font-style: normal;
			opacity: 0;
			position: absolute;
			right: -4px;
			z-index: -1;
			@include translate(30px, 0);

			// Loded anim
			.is-loaded & {
				opacity: 1;
				transition: opacity 0ms ease-in $letter_delay, transform 250ms ease-in $letter_delay_i;
				@include translate(0, 0);
			}

		}

	}


	// Right text
	.txt-right {
		transform: translate(0,0);
		position: absolute;
		color: $secondary-color;
		z-index: -1;
		top: 1px;
		left: 1.65em;

		em {
			opacity: 1;

			// Loded anim
			.is-loaded & {
				opacity: 0;
				transition: opacity 0ms ease-in $letter_delay2;
			}

		}

		// Loded anim
		.is-loaded & {
			transform: translate(-150%,0);
			opacity: 0;

			transition: transform 5s ease-in, opacity 1.5s ease-in 2.5s;
		}

	}

	// Middle letter
	.txt-mid {z-index: 2;}

	// Hidding text wrapper
	.txt-container {
		z-index: -1;
	}

	// Hidding text
	.txt-hide {
		width: 650px;
		position: absolute;
		top: -1px;
		height: 1em;
		left: -15px;
		overflow: hidden;
		z-index: 1;
	}




}


/*
.slogan {
	position: absolute;
	width: 100%;
	z-index: 2;
	font-family: $font_heading;
	color: $cgp_text_dark;
	font-weight: 900;
	line-height: 0.7em;
	//font-size: 214px;
	font-size: 214px;
	@media screen and (max-width: $bkp_hd) {font-size: 11vw;}

	.menu-open & {opacity: 0 !important};

	&--top, &--bottom {
		transition: transform 1500ms cubic-bezier(.44,.32,0,1.43);          
		.is-loaded & { @include translate(0,0); }
	}

	&--top {
		left: 0;
		top: -0.72em;
		text-align: left;
		@include translate(-100%,0);
	}
	&--bottom {
		right: -7.5pt;
		bottom: 8vw;
		text-align: right;
		@include translate(100%,0);
	}
}
*/



/*******************************************************************************
* 0.2 - WHY
*******************************************************************************/

.svg-expand {
	position: relative;

}

$animDuration: 200ms;
$animIterations: 1;
$animDelay: 100ms;
$animTransition: ease-in-out;

.svg-expand-container {

	@media screen and (max-width: 768px) {
		padding-top: 5%;
		padding-bottom: 5%;
	}

	svg {
		overflow: visible;
		height: auto;
		width: 100%;
	}

	// SVG circles
	.circle {

		transition: transform 500ms ease;
		transform-origin: center;

		// Hover
		&:hover {
			cursor: pointer;
		}

	}

	// Scale efect
	.circle6:hover {transform: scale(1.1);}
	.circle5:hover {transform: scale(1.09);}
	.circle4:hover {transform: scale(1.08);}
	.circle3:hover {transform: scale(1.07);}
	.circle2:hover {transform: scale(1.06);}
	.circle1:hover {transform: scale(1.05);}


	// onLoad animation
	&.anim {

		.circle6 {animation: pulse6 $animDuration $animIterations 0ms $animTransition;}
		.circle5 {animation: pulse5 $animDuration $animIterations #{$animDelay * 1} $animTransition;}
		.circle4 {animation: pulse4 $animDuration $animIterations #{$animDelay * 2} $animTransition;}
		.circle3 {animation: pulse3 $animDuration $animIterations #{$animDelay * 3} $animTransition;}
		.circle2 {animation: pulse2 $animDuration $animIterations #{$animDelay * 4} $animTransition;}
		.circle1 {animation: pulse1 $animDuration $animIterations #{$animDelay * 5} $animTransition;}

	}

}

// Keyframes
@keyframes pulse6 {
	0% {transform: scale(1);}
	50% {transform: scale(1.1);}
	100% {transform: scale(1);}
}

@keyframes pulse5 {
	0% {transform: scale(1);}
	50% {transform: scale(1.09);}
	100% {transform: scale(1);}
}

@keyframes pulse4 {
	0% {transform: scale(1);}
	50% {transform: scale(1.08);}
	100% {transform: scale(1);}
}

@keyframes pulse3 {
	0% {transform: scale(1);}
	50% {transform: scale(1.07);}
	100% {transform: scale(1);}
}

@keyframes pulse2 {
	0% {transform: scale(1);}
	50% {transform: scale(1.06);}
	100% {transform: scale(1);}
}

@keyframes pulse1 {
	0% {transform: scale(1);}
	50% {transform: scale(1.05);}
	100% {transform: scale(1);}
}