/*******************************************************************************
* FULL HEIGHT SECTION
*******************************************************************************/

@include breakpoint-min-max(992,1199){

	.full-height-section {
		.center-aligned-content {
			h1 {
				font-size: 28px;
			}
		}
	}
}
@include breakpoint-min-max(768,991){

	.full-height-section {
		.center-aligned-content {
			h1 {
				font-size: 26px;
			}
		}
	}
}
@include breakpoint-max(767){

	.full-height-section {
		.center-aligned-content {
			h1 {
				font-size: 24px;
			}
		}
	}
}

.full-height-section--video {
	background-color: $brand-color !important;
}

.full-height-section{
	min-height: 100vh;  
	position: relative;
	color:$white;
	padding-bottom: 140px;
	width: 100%;
	overflow: hidden;

	&:before {
		z-index: 9;
	}

	.container {position: relative;}

	& > *:not(.parallax-img) {
		z-index: 10;
	}

	.headline-block{
		color: $white;
	}

	.full-height-bg-img{
		@include bg-img;
	}

	.headline-block h2 {
		font-weight: 400;
	}

	.center-aligned-content{
		text-align: center;
		padding: 0 15px;
		max-width: 750px;
		position: absolute;
		top: 60%;
		left: 50%;
		@include translate(-50%, -60%);

		h1{
			margin: 0;
			color: inherit;
			font-weight: $book;
			font-size: 30px;

			@include breakpoint-max(1000) {
				font-size: 28px;
			}

			@include breakpoint-max(570) {
				font-size: 24px;
			}
		}

		&.front-new {
			width: 1100px;
			max-width: 1100px;
			top: 105px;
			height: 100%;
			position: absolute;
			@include horizontal-align();

			@media screen and (max-width: 1920px) {
				width: 1000px;
			}

			@media screen and (max-width: 1440px) {
				width: 750px;
			}

			@media screen and (max-width: 1100px) {
				max-width: 100%;
			}

			h1 {
				font-weight: 400;
              text-transform: uppercase;
			}

			.onSiteWrapBlock {
				&:last-child {
					position: relative;
					top: 35%;
					@include translate(0, -35%);
				}
			}

          h2 {
            color: #fff;
            font-size: 6vh;
            font-weight: 400;
            letter-spacing: -0.22px;
            line-height: 7vh;
		  	max-width: 100%;
		  	margin: 0 auto 0;
		  	position: relative;
		  	top: 30%;
		  	@include translate(0, -30%);

		  	@media screen and (max-width: 768px) and (max-height: 850px) {
				font-size: 40px;
				line-height: 50px;
			}

		  	@media screen and (max-width: 400px) {
				font-size: 30px;
				line-height: 40px;
			}
          }
		}
	}

	.more-link{
		bottom: 104px;
		left: 50%;
		position: absolute;
		color:#ffffff;
		@include translate(-50%,0);
	}

	h2,h3{
		color: inherit;
	}

	h2{
		max-width: 560px;
	}

	h4{
		margin: 1px 0 33px;
	}

	.btn {
		margin: 112px 0 20px;
	}
	.scroll {
		bottom: 36px;
		left: 50%;
		@include sqarebox(30);
		position: absolute;
		margin-left: -15px;
		text-align: center;
		-moz-animation: bounce-arrow 3s infinite;
		-webkit-animation: bounce-arrow 3s infinite;
		animation: bounce-arrow 3s infinite;
	}
}
@-moz-keyframes bounce-arrow {
	0%, 20%, 50%, 80%, 100% {
		-moz-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@-webkit-keyframes bounce-arrow {
	0%, 20%, 50%, 80%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@keyframes bounce-arrow {
	0%, 20%, 50%, 80%, 100% {
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@include breakpoint-min-max(992,1199){
	.full-height-section {
		h2 {
			max-width: 560px;
		}
		.more-link {
			bottom: 54px;
		}
	}
}
@include breakpoint-min-max(768,991){
	.full-height-section {
		.center-aligned-content {
			max-width: 470px;
			width: 100%;
		}
	}
}
@include breakpoint-max(767){
	.full-height-section{
		padding-bottom: 68px;
		.center-aligned-content {
			width: 100%;
			max-width: 300px;
		}
		.headline-block{
			max-width: 100%;
			padding-bottom: 0;
			padding-right: 0;
		}
		h4 {
			margin: 4px 0 14px;
		}
		.more-link {
			bottom: 28px;
		}
		.btn {
			margin: 40px 0 20px;
		}
		.scroll {
			bottom: 16px;
		}
	}
}