/*******************************************************************************
* 0.2 - GENERAL NAVIGATION
*******************************************************************************/

.navigation-trigger {
	float: left;
	cursor: pointer;
	height: 26px;
	padding: 0;
	width: 40px;
	position: relative;
	z-index: 5;
	top: 50%;

	@include translate(0,-50%);
	@include transition(all 0.2s ease-in-out);

	.bars {
		background-color: #fff;
		height: 2px;
		margin-bottom: 10px;
		width: 100%;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		
		&:last-child {margin-bottom: 0;}
		
		//&:nth-child(2){background-color: $brand-color;}

	}

}


.navigation--open {
	.bars{
		&:first-child {
			-webkit-transform: translateY(10px) rotate(135deg);
			transform: translateY(12px) rotate(135deg);
		}
		&:nth-child(2) {
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		&:last-child {
			-webkit-transform: translateY(-10px) rotate(-135deg);
			transform: translateY(-11px) rotate(-135deg);
		}
	}
}


.navigation{
	color: $white;
	font-size: 50px;
	font-weight: $medium;
	line-height: 60px;
	letter-spacing: -0.09px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 998;
	overflow: auto;
	padding: 140px 30px;
	z-index: 1;
	opacity: 0;
	pointer-events: none;
	background-color: rgba(250,170,56,0.8);
	
	@include transition(all 0.4s ease);

	ul,li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		opacity: 0;
		clear: both;
		float: left;
		min-width: 300px;

		@include translate(-100%,0);
		@include transition(all 0.6s ease);
		@include clearfix;


		&:nth-child(1)  {transition-delay: 0.2s;}
		&:nth-child(2)  {transition-delay: 0.3s;}
		&:nth-child(3)  {transition-delay: 0.4s;}
		&:nth-child(4)  {transition-delay: 0.5s;}
		&:nth-child(5)  {transition-delay: 0.6s;}
		&:nth-child(6)  {transition-delay: 0.7s;}
		&:nth-child(7)  {transition-delay: 0.8s;}
		&:nth-child(8)  {transition-delay: 0.9s;}
		&:nth-child(9)  {transition-delay: 1.0s;}
		&:nth-child(10) {transition-delay: 1.1s;}

		&:hover,&.active{
			a {
				color:inherit;
				&:after{
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
				}
			}
		}
	}

	a {
		color: inherit;
		float: left;
		position: relative;

		&:after{
			background-color: #fff;
			bottom: 0;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			width: 100%;
			-webkit-transform: scaleX(0);
			transform: scaleX(0);
			@include transition(all 300ms cubic-bezier(0.645, 0.045, 0.355, 1))
		}

	}

	.social{
		margin: 56px 0 20px;
		padding: 0 7px;
		width: 100%;

		@include inline-block;

		img {
			margin-right: 14px;
			max-height: 25px;
			max-width: 25px;
			position: relative;
			top: -3px;
		}

		a {
			font-size: 30px;
			line-height: 60px;
			letter-spacing: -0.05px;
		}

	}

}


.navigation--open {
	
	.navigation {
		opacity: 1;
		pointer-events: auto;
		
		li {
			opacity: 1;
			@include translate(0,0);
		}

	}

}


@include breakpoint-min-max(768,1199){
	.navigation {
		font-size: 42px;
	}
}                                 


@include breakpoint-max(767){
	.navigation-trigger{
		height: 18px;
		width: 28px;
		.bars{
			height: 1px;
			margin-bottom: 7px;
		}
	}
	.navigation{
		font-size: 24px;
		letter-spacing: -0.04px;
		line-height: 30px;
		padding: 68px 17px;
		li{
			min-width: 140px;
		}
		.social{
			margin: 23px 0 10px;
			padding: 0;
			a{
				line-height: 38px;
				font-size: 18px;
				letter-spacing: -0.03px;
			}
			img{
				left: 0;
				margin-right: 10px;
				max-height: 25px;
				max-width: 20px;
				top: -1px;
			}
		}
	}
	.navigation--open {
		.bars{
			&:first-child {
				-webkit-transform: translateY(7px) rotate(135deg);
				transform: translateY(8px) rotate(135deg);
			}
			&:last-child {
				-webkit-transform: translateY(-7px) rotate(-135deg);
				transform: translateY(-7px) rotate(-135deg);
			}
		}
	}
}